import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { ActionAllowed } from '../rbac/rbacutil';
import { rbacactions } from '../rbac/rbacrules';
import EditIcon from '@material-ui/icons/Edit';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getProductListXlsx } from '../modules/productdata';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    table: {
        width: '100%',
    },
    emptytable: {
        width: '100%',
        padding: '10px',
        fontSize: '0.75em',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    withPadding: {
        padding: '1em',
    },
    chip: {
        margin: '0.5em',
        height: 'auto',
        '& .MuiChip-label': {
            display: 'block',
            whiteSpace: 'normal',
            paddingTop: '7px',
            paddingBottom: '7px',
        },
    },
    searchEndAd: {
        marginRight: '5px',
    },
}));

export default function AllProducts(props) {
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const productList = useSelector((state) => state.productdata.filterProducts);
    const duplicates = useSelector((state) => state.productdata.duplicateProducts);
    const nonStandards = useSelector((state) => state.productdata.nonStandardProducts);
    const userProfile = useSelector((state) => state.authentication.userProfile);
    const history = useHistory();
    const classes = useStyles();
    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');
    const [loadingXlsx, setLoadingXlsx] = useState(false);
    const [showDuplicates, setShowDuplicates] = useState(false);
    const [showNonStandards, setShowNonStandards] = useState(false);
    const [prodSearchList, setProdSearchList] = useState(null);
    const { t } = useTranslation();

    const loadProductListXlsx = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoadingXlsx(false);
            return;
        }
        setLoadingXlsx(true);
        const xlsx = await getProductListXlsx();

        const buff = Buffer.from(xlsx, 'base64');
        const blob = new Blob([buff.buffer], {
            type: 'application/vnd.openxmlformats',
        });
        const url = URL.createObjectURL(blob);
        setBlobUrl(url);

        setTimeout(() => {
            reff.current.click();
        }, 10);
    };

    useEffect(() => {
        if (productList != null && productList.length > 0) {
            setProdSearchList(
                productList.map((p) => {
                    return {
                        ...p,
                        productNameNormalized: p.productName.toLowerCase().replace(/\s/g, ''),
                        productCodeNormalized: p.productCode.toLowerCase().replace(/\s/g, ''),
                        notes:
                            p.notes && p.notes.length > 0
                                ? p.notes.map((n) => {
                                      return { ...n, textNormalized: n.text.toLowerCase().replace(/\s/g, '') };
                                  })
                                : [],
                    };
                })
            );
        }
    }, [productList]);

    const searchFilters = (term) => {
        if (prodSearchList != null && prodSearchList.length > 0 && term != null && term.length >= 3) {
            term = term.toLowerCase().replace(/\s/g, '');
            return prodSearchList.filter(
                (filt) =>
                    filt.productNameNormalized.indexOf(term) !== -1 ||
                    filt.productCodeNormalized.indexOf(term) !== -1 ||
                    (filt.notes && filt.notes.length > 0 && filt.notes.find((n) => n.textNormalized.includes(term)))
            );
        } else return [];
    };
    const handleChange = (e) => {
        if (e.target.value !== '') {
            let prods = searchFilters(e.target.value);
            if (prods.length > 500) {
                prods = prods.slice(0, 500);
            }
            setFilteredProducts(prods);
            setSearchTerm(e.target.value);
        } else {
            setFilteredProducts([]);
        }
    };
    const editProduct = (productId) => {
        history.push(`/product/edit/${productId}`);
    };

    return (
        <div>
            {duplicates && duplicates.length > 0 && (
                <Grid item xs={12}>
                    <br />
                    <Alert severity="warning">
                        {t('product.duplicateCount', { duplicateCount: duplicates.length })}
                        {!showDuplicates && (
                            <Button onClick={() => setShowDuplicates(true)}>{t('buttons.show')}</Button>
                        )}
                        {showDuplicates && (
                            <Button onClick={() => setShowDuplicates(false)}>{t('buttons.hide')}</Button>
                        )}
                        {showDuplicates && (
                            <Grid item>
                                {duplicates.map((dup) => (
                                    <Grid item key={dup[0].id}>
                                        <Link onClick={() => history.push(`/product/edit/${dup[0].id}`)}>
                                            {dup[0].productName}
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Alert>
                </Grid>
            )}
            {nonStandards && nonStandards.length > 0 && (
                <Grid item xs={12}>
                    <br />
                    <Alert severity="warning">
                        {t('product.nonStandardCount', { nonStandardCount: nonStandards.length })}
                        {!showNonStandards && (
                            <Button onClick={() => setShowNonStandards(true)}>{t('buttons.show')}</Button>
                        )}
                        {showNonStandards && (
                            <Button onClick={() => setShowNonStandards(false)}>{t('buttons.hide')}</Button>
                        )}
                        {showNonStandards && (
                            <Grid item>
                                {nonStandards.map((ns) => (
                                    <Grid item key={`nsp-${ns.id}`}>
                                        <Link onClick={() => history.push(`/product/edit/${ns.id}`)}>
                                            {ns.productName}
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </Alert>
                </Grid>
            )}
            <br />
            <Grid item xs={12}>
                <em>Hae tuotetta nimellä tai koodilla</em>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    id="standard-multiline-flexible"
                    label={t('searchHint')}
                    className={classes.root}
                    onChange={handleChange}
                    margin="normal"
                    name="hakusana"
                    InputProps={{
                        endAdornment:
                            searchTerm && searchTerm.length > 0 ? (
                                <span className={classes.searchEndAd}>{filteredProducts.length}</span>
                            ) : null,
                    }}
                />
            </Grid>
            {filteredProducts !== null && filteredProducts.length === 500 && (
                <Grid item container xs={12}>
                    <Paper className={classes.emptytable}>
                        <strong>{t('product.over500searchResults')}</strong>
                    </Paper>
                </Grid>
            )}
            {filteredProducts !== null && filteredProducts.length > 0 && (
                <Grid item container xs={12}>
                    <Paper className={classes.table}>
                        <div className={classes.sectionDesktop}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('product.type')}</TableCell>
                                        <TableCell>{t('product.name')}</TableCell>
                                        <TableCell>{t('product.notes')}</TableCell>
                                        <TableCell>{t('invoice.priceVatZero')}</TableCell>
                                        <TableCell>{t('general.edited')}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProducts.map((f) => (
                                        <TableRow key={f.id}>
                                            <TableCell>{f.type}</TableCell>
                                            <TableCell>{f.productName}</TableCell>
                                            <TableCell>
                                                {f.notes &&
                                                    f.notes.length > 0 &&
                                                    f.notes.map((note) => (
                                                        <Chip
                                                            key={note.storageId}
                                                            className={classes.chip}
                                                            label={note.text}
                                                        />
                                                    ))}
                                            </TableCell>
                                            <TableCell>{f.priceWithoutVat}</TableCell>
                                            <TableCell>
                                                <Tooltip title={f.addedByUser}>
                                                    <span>{moment(f.added).format('D.M.YYYY HH:mm')}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    disabled={!ActionAllowed(rbacactions.ModifyProduct, userProfile)}
                                                    variant="contained"
                                                    onClick={() => editProduct(f.id)}
                                                    startIcon={<EditIcon />}>
                                                    {t('buttons.edit')}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                        <div className={classes.sectionMobile}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('general.product')}</TableCell>
                                        <TableCell>{t('invoice.priceVatZero')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredProducts.map((f) => (
                                        <TableRow key={f.id}>
                                            <TableCell>
                                                {f.productCode}
                                                <br />
                                                {f.productName}
                                                <br />
                                                <Button
                                                    disabled={!ActionAllowed(rbacactions.ModifyProduct, userProfile)}
                                                    variant="contained"
                                                    onClick={() => editProduct(f.id)}
                                                    startIcon={<EditIcon />}>
                                                    {t('buttons.edit')}
                                                </Button>
                                            </TableCell>
                                            <TableCell>{f.priceWithoutVat}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </Paper>
                </Grid>
            )}
            {filteredProducts !== null && filteredProducts.length === 0 && (
                <Grid item container xs={12}>
                    <Paper className={classes.emptytable}>{t('general.noSearchResults')}</Paper>
                </Grid>
            )}
            <Footer>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!ActionAllowed(rbacactions.CreateNewProduct, userProfile)}
                        name="btn-new-prod"
                        onClick={() => history.push('/product/edit/new')}
                        startIcon={<AddIcon />}>
                        {t('general.product')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        ref={reff}
                        variant="contained"
                        color="primary"
                        disabled={loadingXlsx || !ActionAllowed(rbacactions.LoadProductList, userProfile)}
                        name="btn-load-product-xsls"
                        onClick={loadProductListXlsx}
                        href={blobUrl.length > 0 ? blobUrl : null}
                        download={blobUrl.length > 0 ? `Kaikki_tuotteet_${moment().format()}.xlsx` : null}
                        startIcon={loadingXlsx ? <CircularProgress color={'secondary'} size={'1rem'} /> : null}>
                        {t('location.buttons.download')}
                    </Button>
                </Grid>
            </Footer>
            <FooterPadding />
        </div>
    );
}
