import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../common/spinner';
import Container from '@material-ui/core/Container';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { getLocationFull as getLocationFullSales, getLocationEnergyReportExcel } from '../modules/locationdata';
import { getLocationFull as getLocationFullCustomer } from '../modules/customerlocationdata';
import { cloneDeep, uniqBy } from 'lodash';
import CircularProgress from '@material-ui/core/CircularProgress';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { getEuroventReferenceData } from '../modules/productdata';
import FilterProduct from '../domain/filterproduct';
import { InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    text: {
        padding: '5px',
        width: '20%',
    },
}));

const delay = async (ms) => {
    await new Promise((r) => setTimeout(r, ms));
};

function MachineRow(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const m = props.machine;
    const update = props.update;
    const filterMapPerMachine = props.filterMapPerMachine;
    const vadoReferencesInEurovent = props.vadoReferencesInEurovent;
    const filteredCompReferences = props.filteredCompReferences;
    const [vadoRef, setVadoRef] = useState(null);
    const [competitorRef, setCompetitorRef] = useState(null);
    const [filtersComp, setFiltersComp] = useState(null);

    useEffect(() => {
        if (m && m.filters && m.filters.length > 0) {
            let compFilt = [];
            for (const f of m.filters) {
                if (f.product) {
                    compFilt.push({
                        id: f.id,
                        bpProductId: null,
                        count: f.count,
                        width: f.product.dimensionWidthMm,
                        heigth: f.product.dimensionHeightMm,
                        depth: f.product.dimensionDepthMm,
                        numberOfPockets: f.product.numberOfPockets,
                    });
                } else {
                    for (const bp of f.bundle.products) {
                        compFilt.push({
                            id: f.id,
                            bpProductId: bp.product.id,
                            count: f.count * bp.productCount,
                            width: bp.product.dimensionWidthMm,
                            heigth: bp.product.dimensionHeightMm,
                            depth: bp.product.dimensionDepthMm,
                            numberOfPockets: bp.product.numberOfPockets,
                        });
                    }
                }
            }
            setFiltersComp(compFilt);
        }
    }, [m]);

    useEffect(() => {
        if (filterMapPerMachine && m) {
            if (filterMapPerMachine[m.id]) {
                setVadoRef(filterMapPerMachine[m.id]);
            }
        }
    }, [filterMapPerMachine, m]);

    // object with machine id, each object:
    // - id (machine Id)
    // - vadoRefProduct
    // - compRefProduct
    // - compFilterParameters, array of:
    //   - id (location filter id)
    //   - count
    //   - width
    //   - heigth
    //   - depth
    //   - numberOfPockets
    useEffect(() => {
        if (filtersComp && filtersComp.length > 0) {
            update(m.id, {
                id: m.id,
                vadoRefProduct: vadoRef,
                compRefProduct: competitorRef,
                compFilterParameters: filtersComp,
            });
        }
    }, [vadoRef, competitorRef, filtersComp, m, update]);

    const getCompFilter = (fId, prodBundleProdId) => {
        if (filtersComp && filtersComp.length > 0) {
            if (prodBundleProdId) return filtersComp.find((f) => f.id === fId && f.bpProductId === prodBundleProdId);
            return filtersComp.find((f) => f.id === fId);
        }
        return null;
    };

    const getCompFilterIndex = (fId, prodBundleProdId) => {
        if (filtersComp && filtersComp.length > 0) {
            if (prodBundleProdId)
                return filtersComp.findIndex((f) => f.id === fId && f.bpProductId === prodBundleProdId);

            return filtersComp.findIndex((f) => f.id === fId);
        }
        throw new Error('error');
    };

    const onChangeCompFilterCount = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filtersComp);
        const newFilt = cloneDeep(getCompFilter(fId, bpProdId));
        const ind = getCompFilterIndex(fId, bpProdId);
        newFilt.count = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFiltersComp(newFilters);
    };
    const onChangeCompFilterWidth = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filtersComp);
        const newFilt = cloneDeep(getCompFilter(fId, bpProdId));
        const ind = getCompFilterIndex(fId, bpProdId);
        newFilt.width = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFiltersComp(newFilters);
    };
    const onChangeCompFilterHeigth = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filtersComp);
        const newFilt = cloneDeep(getCompFilter(fId, bpProdId));
        const ind = getCompFilterIndex(fId, bpProdId);
        newFilt.heigth = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFiltersComp(newFilters);
    };
    const onChangeCompFilterDepth = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filtersComp);
        const newFilt = cloneDeep(getCompFilter(fId, bpProdId));
        const ind = getCompFilterIndex(fId, bpProdId);
        newFilt.depth = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFiltersComp(newFilters);
    };
    const onChangeCompFilterPockets = (fId, bpProdId, event) => {
        let newFilters = cloneDeep(filtersComp);
        const newFilt = cloneDeep(getCompFilter(fId, bpProdId));
        const ind = getCompFilterIndex(fId, bpProdId);
        newFilt.numberOfPockets = parseInt(event.target.value);
        newFilters[ind] = newFilt;
        setFiltersComp(newFilters);
    };

    const onSelectVadoRef = (event) => {
        const refProd = vadoReferencesInEurovent.find((p) => p.product.productName === event.target.value);
        setVadoRef(refProd);
    };

    const onSelectCompRef = (event) => {
        const selected = filteredCompReferences.find((p) => p.storageId === event.target.value);
        let newFilters = cloneDeep(filtersComp);
        let changes = false;
        if (selected && newFilters && newFilters.length) {
            for (let f of newFilters) {
                if (selected.depthOrLengthMm_21 && selected.depthOrLengthMm_21.length) {
                    changes = true;
                    f.depth = parseInt(selected.depthOrLengthMm_21);
                }
                if (selected.noOfBagsOrVs_23 && selected.noOfBagsOrVs_23.length) {
                    changes = true;
                    f.numberOfPockets = parseInt(selected.noOfBagsOrVs_23);
                }
            }
        }
        if (changes) setFiltersComp(newFilters);
        setCompetitorRef(selected);
    };

    return (
        <React.Fragment>
            <TableRow key={m.id}>
                <TableCell></TableCell>
                <TableCell>
                    <strong>{m.name},&nbsp;</strong>
                    {t('location.airVolume')}: <strong>{m.airVolume} m&#179;/s</strong>, {t('location.position')}:{' '}
                    <strong>{m.machineLocation}</strong>, {t('location.serviceArea')}: <strong>{m.machineArea}</strong>
                    ,&nbsp;<strong>Pinta-ala</strong>:&nbsp;{m.totalSurfaceArea()} m2
                    <br />
                    {vadoReferencesInEurovent && (
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id={`${m.id}-vado-ref-select-label`}>Vado referenssituote</InputLabel>
                            <Select
                                required
                                error={false}
                                value={vadoRef ? vadoRef.product.productName : '-'}
                                labelId={`${m.id}-vado-ref-select-label`}
                                id={`${m.id}-vado-ref-select`}
                                name="vado-ref-product-select"
                                onChange={(e) => onSelectVadoRef(e)}>
                                <MenuItem value={'-'}>-Valitse-</MenuItem>
                                {vadoReferencesInEurovent &&
                                    vadoReferencesInEurovent.map((prod) => (
                                        <MenuItem value={prod.product.productName}>{prod.product.productName}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                    {filteredCompReferences && (
                        <FormControl style={{ width: '100%' }}>
                            <InputLabel id={`${m.id}-competitor-product-select-label`}>
                                {t('product.competitorProductName')}
                            </InputLabel>
                            <Select
                                required
                                error={false}
                                value={competitorRef ? competitorRef.storageId : '-'}
                                labelId={`${m.id}-competitor-product-select-label`}
                                id={`${m.id}-competitor-product-select`}
                                name="competitor-product-select"
                                onChange={(e) => onSelectCompRef(e)}>
                                <MenuItem value={'-'}>-Valitse-</MenuItem>
                                {filteredCompReferences &&
                                    filteredCompReferences.map((prod) => (
                                        <MenuItem value={prod.storageId}>{prod.model_3}</MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </TableCell>
            </TableRow>
            {m.filters.map((f) => (
                <React.Fragment>
                    {f.product && (
                        <TableRow key={f.id}>
                            <TableCell>{f.count}</TableCell>
                            <TableCell>{f.product.productName}</TableCell>
                            <TableCell>
                                {getCompFilter(f.id) && (
                                    <TextField
                                        label={'kpl'}
                                        value={getCompFilter(f.id).count}
                                        onChange={(e) => onChangeCompFilterCount(f.id, null, e)}></TextField>
                                )}
                            </TableCell>
                            <TableCell>
                                {getCompFilter(f.id) && (
                                    <Grid item container>
                                        <Grid item>
                                            <TextField
                                                className={classes.text}
                                                label={'Leveys'}
                                                value={getCompFilter(f.id).width}
                                                onChange={(e) => onChangeCompFilterWidth(f.id, null, e)}></TextField>

                                            <TextField
                                                className={classes.text}
                                                label={'Korkeus'}
                                                value={getCompFilter(f.id).heigth}
                                                onChange={(e) => onChangeCompFilterHeigth(f.id, null, e)}></TextField>

                                            <TextField
                                                className={classes.text}
                                                label={'Syvyys'}
                                                value={getCompFilter(f.id).depth}
                                                onChange={(e) => onChangeCompFilterDepth(f.id, null, e)}></TextField>

                                            <TextField
                                                className={classes.text}
                                                label={'Taskuja'}
                                                value={getCompFilter(f.id).numberOfPockets}
                                                onChange={(e) => onChangeCompFilterPockets(f.id, null, e)}></TextField>
                                        </Grid>
                                    </Grid>
                                )}
                            </TableCell>
                        </TableRow>
                    )}
                    {f.bundle && (
                        <React.Fragment>
                            {f.bundle.products.map((bp) => (
                                <TableRow id={bp.product.id}>
                                    <TableCell>{f.count * bp.productCount}</TableCell>
                                    <TableCell>
                                        {bp.product.productName} ({f.bundle.name})
                                    </TableCell>
                                    <TableCell>
                                        {getCompFilter(f.id, bp.product.id) && (
                                            <TextField
                                                label={'kpl'}
                                                value={getCompFilter(f.id, bp.product.id).count}
                                                onChange={(e) =>
                                                    onChangeCompFilterCount(f.id, bp.product.id, e)
                                                }></TextField>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {getCompFilter(f.id, bp.product.id) && (
                                            <Grid item container>
                                                <Grid item>
                                                    <TextField
                                                        className={classes.text}
                                                        label={'Leveys'}
                                                        value={getCompFilter(f.id, bp.product.id).width}
                                                        onChange={(e) =>
                                                            onChangeCompFilterWidth(f.id, bp.product.id, e)
                                                        }></TextField>

                                                    <TextField
                                                        className={classes.text}
                                                        label={'Korkeus'}
                                                        value={getCompFilter(f.id, bp.product.id).heigth}
                                                        onChange={(e) =>
                                                            onChangeCompFilterHeigth(f.id, bp.product.id, e)
                                                        }></TextField>

                                                    <TextField
                                                        className={classes.text}
                                                        label={'Syvyys'}
                                                        value={getCompFilter(f.id, bp.product.id).depth}
                                                        onChange={(e) =>
                                                            onChangeCompFilterDepth(f.id, bp.product.id, e)
                                                        }></TextField>

                                                    <TextField
                                                        className={classes.text}
                                                        label={'Taskuja'}
                                                        value={getCompFilter(f.id, bp.product.id).numberOfPockets}
                                                        onChange={(e) =>
                                                            onChangeCompFilterPockets(f.id, bp.product.id, e)
                                                        }></TextField>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </React.Fragment>
                    )}
                </React.Fragment>
            ))}
            <TableRow>
                <TableCell>
                    <div style={{ height: '10px' }}> </div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function LocationViewEnergy(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerUi = process.env.REACT_APP_TARGET === 'customerui';
    const locationId = parseInt(props.match.params.locationId, 10);
    const locationsByIdCust = useSelector((state) => state.customerlocationdata.locationsById);
    const locationsByIdVado = useSelector((state) => state.locationdata.locationsById);
    const locationFull = customerUi ? locationsByIdCust[locationId] : locationsByIdVado[locationId];
    const products = useSelector((state) => state.productdata.filterProducts);
    const [reff] = useState(React.createRef());
    const [blobUrl, setBlobUrl] = useState('');

    const [euroventData, setEuroventData] = useState(null);
    const [vadoEuroventData, setVadoEuroventData] = useState(null);
    const [filterMapPerMachine, setFilterMapPerMachine] = useState(null);
    const [loadingEnergyXlsx, setLoadingEnergyXlsx] = useState(false);
    const [selectEPMClasses, setSelectEPMClasses] = useState([]);
    const [selectBrands, setSelectBrands] = useState([]);
    const [selectEffPercents, setSelectEffPercents] = useState([]);
    const [selectRanges, setSelectRanges] = useState([]);

    const [selectedBrand, setSelectedBrand] = useState('-');
    const [selectedRange, setSelectedRange] = useState('-');
    const [selectedEPMClass, setSelectedEPMClass] = useState('-');
    const [selectedEffPercent, setSelectedEffPercent] = useState('-');
    const [filteredRefProducts, setFilteredRefProducts] = useState([]);

    // object with machine id, each object:
    // - id (machine Id)
    // - vadoRefProduct
    // - compRefProduct
    // - compFilterParameters, array of:
    //   - id (location filter id)
    //   - count
    //   - width
    //   - heigth
    //   - depth
    //   - numberOfPockets
    const refDatasPerMachineRef = useRef({});

    useEffect(() => {
        async function getData() {
            var data = await getEuroventReferenceData();
            setEuroventData(data);
            const vadoFiltersInEurovent = data
                .filter((filt) => filt.brand_1 === 'VADO')
                .map((item) => ({
                    product: FilterProduct.ParseProductFromEuroventName(item.model_3),
                    euroventData: {
                        ...item,
                        curve: [
                            { avp: 50, pdPa: parseInt(item.initPressDropAt50percentPa_11) },
                            { avp: 75, pdPa: parseInt(item.initPressDropAt75percentPa_12) },
                            { avp: 100, pdPa: parseInt(item.initPressDropAt100percentPa_10) },
                            { avp: 125, pdPa: parseInt(item.initPressDropAt125percentPa_13) },
                        ],
                    },
                }));
            setVadoEuroventData(vadoFiltersInEurovent);
        }
        getData();
    }, []);

    const findReferenceFilterFromEuroventData = useCallback(
        (prodName) => {
            const toMatch = products.filter((f) => f.productName === prodName)[0];
            if (!toMatch) return null;

            const vadoFiltersInEurovent = euroventData
                .filter((filt) => filt.brand_1 === 'VADO')
                .map((item) => ({
                    product: FilterProduct.ParseProductFromEuroventName(item.model_3),
                    euroventData: {
                        ...item,
                        curve: [
                            { avp: 50, pdPa: parseInt(item.initPressDropAt50percentPa_11) },
                            { avp: 75, pdPa: parseInt(item.initPressDropAt75percentPa_12) },
                            { avp: 100, pdPa: parseInt(item.initPressDropAt100percentPa_10) },
                            { avp: 125, pdPa: parseInt(item.initPressDropAt125percentPa_13) },
                        ],
                    },
                }));
            // 1) find direct match (all dimensions, material, pockets)
            let match = vadoFiltersInEurovent.filter(
                (f) =>
                    f.product.type === toMatch.legacyType &&
                    f.product.material === toMatch.material &&
                    f.product.filterClass === toMatch.filterClass &&
                    f.product.classP === toMatch.classP &&
                    f.product.dimensionWidthMm === toMatch.dimensionWidthMm &&
                    f.product.dimensionHeightMm === toMatch.dimensionHeightMm &&
                    f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                    f.product.numberOfPockets === toMatch.numberOfPockets
            );
            //if (match.length > 0) {
            //match[0].exactMatch = true;
            //}

            // 2) Find match with same depth & pockets & width but different height
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionWidthMm === toMatch.dimensionWidthMm &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.product.numberOfPockets === toMatch.numberOfPockets
                );

            // 3) Find match with same depth & pockets & height but different width
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionHeightMm === toMatch.dimensionHeightMm &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.product.numberOfPockets === toMatch.numberOfPockets
                );

            // 4) Find match with same depth & pockets but width&height differs
            if (match.length === 0)
                match = vadoFiltersInEurovent.filter(
                    (f) =>
                        f.product.type === toMatch.legacyType &&
                        f.product.material === toMatch.material &&
                        f.product.filterClass === toMatch.filterClass &&
                        f.product.classP === toMatch.classP &&
                        f.product.dimensionDepthMm === toMatch.dimensionDepthMm &&
                        f.numberOfPockets === toMatch.numberOfPockets
                );
            return match.length > 0 ? match[0] : null;
        },
        [euroventData, products]
    );

    useEffect(() => {
        if (euroventData && locationFull && products) {
            const filterMapMachines = {};

            if (locationFull.machines) {
                for (const m of locationFull.machines) {
                    if (m.filters && m.filters.length > 0) {
                        if (m.filters[0].product) {
                            filterMapMachines[m.id] = findReferenceFilterFromEuroventData(
                                m.filters[0].product.productName
                            );
                        } else {
                            filterMapMachines[m.id] = findReferenceFilterFromEuroventData(
                                m.filters[0].bundle.products[0].product.productName
                            );
                        }
                    }
                }
            }
            setFilterMapPerMachine(filterMapMachines);
            setSelectBrands(uniqBy(euroventData, 'brand_1').map((elem) => elem.brand_1));
        }
    }, [euroventData, locationFull, products, findReferenceFilterFromEuroventData]);

    useEffect(() => {
        if (!locationFull) {
            if (customerUi) dispatch(getLocationFullCustomer(locationId));
            else dispatch(getLocationFullSales(locationId));
        }
    }, [locationFull, customerUi, dispatch, locationId]);

    useEffect(() => {
        if (selectedBrand !== '-') {
            setSelectRanges(
                uniqBy(
                    euroventData.filter((r) => r.brand_1 === selectedBrand),
                    'range_2'
                ).map((elem) => elem.range_2)
            );
        } else {
            setSelectRanges([]);
        }
    }, [selectedBrand, euroventData]);

    useEffect(() => {
        if (selectedRange !== '-' && selectedBrand !== '-') {
            setSelectEPMClasses(
                uniqBy(
                    euroventData.filter((r) => r.brand_1 === selectedBrand && r.range_2 === selectedRange),
                    'ePMGroupISO16890_5'
                ).map((elem) => elem.ePMGroupISO16890_5)
            );
        } else {
            setSelectEPMClasses([]);
        }
    }, [selectedRange, euroventData, selectedBrand]);

    useEffect(() => {
        if (selectedEPMClass !== '-' && selectedBrand !== '-' && selectedRange !== '-') {
            setSelectEffPercents(
                uniqBy(
                    euroventData.filter(
                        (r) =>
                            r.brand_1 === selectedBrand &&
                            r.range_2 === selectedRange &&
                            r.ePMGroupISO16890_5 === selectedEPMClass
                    ),
                    'efficiencyClassISO16890Percent_7'
                ).map((elem) => elem.efficiencyClassISO16890Percent_7)
            );
        } else {
            setSelectEffPercents([]);
        }
    }, [selectedEPMClass, euroventData, selectedBrand, selectedRange]);

    useEffect(() => {
        let filteredList = euroventData;
        if (selectedBrand !== '-') {
            filteredList = filteredList.filter((r) => r.brand_1 === selectedBrand);
        }
        if (selectedRange !== '-') {
            filteredList = filteredList.filter((r) => r.range_2 === selectedRange);
        }
        if (selectedEPMClass !== '-') {
            filteredList = filteredList.filter((r) => r.ePMGroupISO16890_5 === selectedEPMClass);
        }
        if (selectedEffPercent !== '-') {
            filteredList = filteredList.filter((r) => r.efficiencyClassISO16890Percent_7 === selectedEffPercent);
        }
        setFilteredRefProducts(filteredList);
    }, [selectedBrand, selectedEPMClass, selectedEffPercent, selectedRange, euroventData]);

    const loadLocationEnergyXlsx = async (e) => {
        if (blobUrl) {
            e.stopPropagation();
            await delay(100);
            setBlobUrl('');
            setLoadingEnergyXlsx(false);
            return;
        }
        setLoadingEnergyXlsx(true);

        const refDatas = locationFull.machines.map((m) => {
            return {
                machine: m,
                parameters: refDatasPerMachineRef.current[m.id],
            };
        });

        const xlsx = await getLocationEnergyReportExcel(locationId, refDatas);

        if (xlsx) {
            const buff = Buffer.from(xlsx, 'base64');
            const blob = new Blob([buff.buffer], {
                type: 'application/vnd.openxmlformats',
            });
            const url = URL.createObjectURL(blob);
            setBlobUrl(url);

            setTimeout(() => {
                reff.current.click();
            }, 10);
        } else {
            setLoadingEnergyXlsx(false);
        }
    };

    const close = () => {
        props.history.goBack();
    };

    const onSelectBrand = (elem) => {
        setSelectedBrand(elem.target.value);
        setSelectedRange('-');
        setSelectedEPMClass('-');
        setSelectedEffPercent('-');
    };
    const onSelectRange = (elem) => {
        setSelectedRange(elem.target.value);
        setSelectedEPMClass('-');
        setSelectedEffPercent('-');
    };
    const onSelectEPMClass = (elem) => {
        setSelectedEPMClass(elem.target.value);
        setSelectedEffPercent('-');
    };
    const onSelectEffPercent = (elem) => {
        setSelectedEffPercent(elem.target.value);
    };

    const updateMachineParameters = (machineId, data) => {
        const refDatasNew = cloneDeep(refDatasPerMachineRef.current);
        refDatasNew[machineId] = data;
        refDatasPerMachineRef.current = refDatasNew;
    };

    if (customerUi) return null;

    return (
        <Container maxWidth={false}>
            <Grid item xs={12}>
                {locationFull != null && (
                    <h2>
                        <Button onClick={close}>
                            <i className="fas fa-chevron-left fa-2x"></i>
                            &nbsp;&nbsp;
                        </Button>
                        {locationFull.name}
                    </h2>
                )}
                {!locationFull && <Spinner padding="20px" title={t('general.loading')} />}
                {locationFull && (
                    <Grid item>
                        {!customerUi && (
                            <Grid item>
                                <h3>Vertailutuotevalinnat</h3>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="user-status-label">Merkki</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="user-status-label"
                                            id="userStatus"
                                            name="userStatus"
                                            value={selectedBrand}
                                            onChange={onSelectBrand}>
                                            <MenuItem value={'-'}>-Valitse-</MenuItem>
                                            {selectBrands &&
                                                selectBrands.map((elem) => (
                                                    <MenuItem id={elem} value={elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="user-status-label">Range</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="user-status-label"
                                            id="userStatus"
                                            name="userStatus"
                                            value={selectedRange}
                                            onChange={onSelectRange}>
                                            <MenuItem value={'-'}>-Valitse-</MenuItem>
                                            {selectRanges &&
                                                selectRanges.map((elem) => (
                                                    <MenuItem id={elem} value={elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="user-status-label">ePM</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="user-status-label"
                                            id="userStatus"
                                            name="userStatus"
                                            value={selectedEPMClass}
                                            onChange={onSelectEPMClass}>
                                            <MenuItem value={'-'}>-Valitse-</MenuItem>
                                            {selectEPMClasses &&
                                                selectEPMClasses.map((elem) => (
                                                    <MenuItem id={elem} value={elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="user-status-label">Suodatustehokkuus %</InputLabel>
                                        <Select
                                            required
                                            error={false}
                                            labelId="user-status-label"
                                            id="userStatus"
                                            name="userStatus"
                                            value={selectedEffPercent}
                                            onChange={onSelectEffPercent}>
                                            <MenuItem value={'-'}>-Valitse-</MenuItem>
                                            {selectEffPercents &&
                                                selectEffPercents.map((elem) => (
                                                    <MenuItem id={elem} value={elem}>
                                                        {elem}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )}
                        <br />

                        {locationFull.machines && (
                            <Table size="small">
                                <colgroup>
                                    <col width="5%" />
                                    <col width="50%" />
                                    <col width="5%" />
                                    <col width="50%" />
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Kpl</TableCell>
                                        <TableCell>Vado</TableCell>
                                        <TableCell>Kpl</TableCell>
                                        <TableCell>Kilpailija</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {locationFull.machines.map((m) => (
                                        <MachineRow
                                            key={m.id}
                                            machine={m}
                                            filterMapPerMachine={filterMapPerMachine}
                                            vadoReferencesInEurovent={vadoEuroventData}
                                            filteredCompReferences={filteredRefProducts}
                                            update={updateMachineParameters}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </Grid>
                )}
            </Grid>
            <br />
            <br />
            <Footer>
                {customerUi === false && (
                    <Grid item>
                        <Button
                            ref={reff}
                            variant="contained"
                            color="primary"
                            name="btn-load-location-energy-xsls"
                            onClick={loadLocationEnergyXlsx}
                            href={blobUrl.length > 0 ? blobUrl : null}
                            download={blobUrl.length > 0 ? `${locationFull.name}_${moment().format()}.xlsx` : null}
                            startIcon={
                                loadingEnergyXlsx ? (
                                    <CircularProgress color={'secondary'} size={'1rem'} />
                                ) : (
                                    <OfflineBoltIcon />
                                )
                            }>
                            {t('location.buttons.downloadExcel')}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button variant="contained" color="secondary" name="btn-close" onClick={close}>
                        {t('buttons.close')}
                    </Button>
                </Grid>
                {customerUi === false && (
                    <Grid item>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Energialaskelma</FormLabel>
                            <Checkbox
                                checked={true}
                                onChange={() => {
                                    props.history.goBack();
                                }}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </FormControl>
                    </Grid>
                )}
            </Footer>

            <FooterPadding />
        </Container>
    );
}
