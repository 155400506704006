import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';
import CapacityReservation from '../domain/capacityreservation';
import CapacityAllocation from '../domain/capacityallocation';
import CapacityGroupCapacityAllocation from '../domain/capacitygroupcapacityallocation';
import AvailableCapacityPerDayAndProductType from '../domain/availablecapacityperdayandproducttype';
import ProductType from '../domain/producttype';
import moment from 'moment';
import { groupBy, cloneDeep } from 'lodash';
import {
    formatWeekIdentifierInt,
    formatWeekIdentifierMoment,
    generateContinuousWeekList,
} from '../order/capacityplanner';
import CapacityGroup from '../domain/capacitygroup';

export const PROD_CAPACITY_DATA_LOADED = 'prodplanning/capacitydataloaded';
export const PROD_CAPACITY_DATA_LOADING = 'prodplanning/capacitydataloading';
export const CAPACITY_RESERVATIONS_LOADED = 'prodplanning/capacityreservationsloaded';
export const CAPACITY_RESERVATIONS_LOADING = 'prodplanning/capacityreservationsloading';
export const CAPACITY_RESERVATIONS_SAVING = 'prodplanning/capacityreservationssaving';
export const CAPACITY_RESERVATIONS_SAVED = 'prodplanning/capacityreservationssaved';
export const CAPACITY_RESERVATIONS_SAVE_FAIL = 'prodplanning/capacityreservationssavefail';
export const CAPACITY_ALLOCATIONS_SAVING = 'prodplanning/capacityallocationssaving';
export const CAPACITY_ALLOCATIONS_SAVED = 'prodplanning/capacityallocationssaved';
export const CAPACITY_ALLOCATIONS_SAVE_FAIL = 'prodplanning/capacityallocationssavefail';

export const PROD_CAPACITY_ALLOC_DATA_LOADED_PROD_TYPE = 'prodplanning/capacitydataloaded_ALLOC_PROD_TYPE';
export const PROD_CAPACITY_ALLOC_DATA_LOADING_PROD_TYPE = 'prodplanning/capacitydataloading_ALLOC_PROD_TYPE';
export const PROD_CAPACITY_ALLOC_DATA_SAVED_PROD_TYPE = 'prodplanning/PROD_CAPACITY_ALLOC_DATA_SAVED_PROD_TYPE';
export const PROD_CAPACITY_ALLOC_DATA_SAVING_PROD_TYPE = 'prodplanning/PROD_CAPACITY_ALLOC_DATA_SAVING_PROD_TYPE';
export const PROD_CAPACITY_ALLOC_DATA_SAVE_FAILED_PROD_TYPE =
    'prodplanning/PROD_CAPACITY_ALLOC_DATA_SAVE_FAILED_PROD_TYPE';
export const PROD_AVAILABLE_CAPA_LOADING = 'prodplanning/PROD_AVAILABLE_CAPA_LOADING';
export const PROD_AVAILABLE_CAPA_LOADED = 'prodplanning/PROD_AVAILABLE_CAPA_LOADED';
export const PROD_AVAILABLE_CAPA_LOAD_FAILED = 'prodplanning/PROD_AVAILABLE_CAPA_LOAD_FAILED';
export const PROD_CAPACITY_PRODUCT_TYPES_LOADING = 'prodplanning/PROD_CAPACITY_PRODUCT_TYPES_LOADING';
export const PROD_CAPACITY_PRODUCT_TYPES_LOADED = 'prodplanning/PROD_CAPACITY_PRODUCT_TYPES_LOADED';
export const PROD_CAPACITY_PRODUCT_TYPES_LOAD_FAILED = 'prodplanning/PROD_CAPACITY_PRODUCT_TYPES_LOAD_FAILED';
export const PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVING = 'prodplanning/PRODUCT_TYPE_capacityreservationssaving';
export const PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVED = 'prodplanning/PRODUCT_TYPE_capacityreservationssaved';
export const PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVE_FAIL = 'prodplanning/PRODUCT_TYPE_capacityreservationssavefail';
export const PROD_CAPACITY_CAPACITY_GROUPS_LOADING = 'prodplanning/PROD_CAPACITY_CAPACITY_GROUPS_LOADING';
export const PROD_CAPACITY_CAPACITY_GROUPS_LOADED = 'prodplanning/PROD_CAPACITY_CAPACITY_GROUPS_LOADED';
export const PROD_CAPACITY_CAPACITY_GROUPS_LOAD_FAILED = 'prodplanning/PROD_CAPACITY_CAPACITY_GROUPS_LOAD_FAILED';

const initialState = {
    capacityAllocationsByWeek: {},
    loadingCapacityData: false,
    capacityReservations: null,
    loadingCapacityReservations: false,
    savingCapacityReservations: false,
    productTypeCapacityAllocations: {},
    productTypeCapacityAllocationsLoading: false,
    productTypes: null,
    productTypesLoading: false,
    productTypeAvailableCapacity: null,
    productTypeAvailableCapacityLoading: false,
    capacityGroups: null,
    capacityGroupsLoading: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case PROD_CAPACITY_DATA_LOADING:
            return {
                ...state,
                loadingCapacityData: true,
            };
        case PROD_CAPACITY_DATA_LOADED: {
            const allocations = action.data.map((a) => {
                return new CapacityAllocation(a);
            });

            // Täytä aukot tyhjällä taulukolla
            // jos datassa ei ole jokaiselle viikolla jotain dataa, täytetään aukot tyhjällä taulukolla
            // indikoi että data haettu mutta sitä ei ole.
            const storeData = cloneDeep(state.capacityAllocationsByWeek);
            const expectedWeeks = generateContinuousWeekList(action.startWeek, action.endWeek);
            const grouped = groupBy(allocations, (item) => `${formatWeekIdentifierInt(item.year, item.week)}`);
            for (const w of expectedWeeks) {
                storeData[w] = grouped[w] ? grouped[w] : [];
            }

            return {
                ...state,
                loadingCapacityData: false,
                capacityAllocationsByWeek: storeData,
            };
        }
        case PROD_CAPACITY_ALLOC_DATA_LOADING_PROD_TYPE: {
            return {
                ...state,
                productTypeLoadCapacityAllocations: true,
            };
        }
        case PROD_CAPACITY_ALLOC_DATA_LOADED_PROD_TYPE: {
            const allocations = action.data.map((a) => {
                return new CapacityGroupCapacityAllocation(a);
            });

            // Täytä aukot tyhjällä taulukolla
            // jos datassa ei ole jokaiselle viikolla jotain dataa, täytetään aukot tyhjällä taulukolla
            // indikoi että data haettu mutta sitä ei ole.
            const storeData = cloneDeep(state.productTypeCapacityAllocations);
            const expectedWeeks = generateContinuousWeekList(action.startWeek, action.endWeek);
            const grouped = groupBy(allocations, (item) => `${formatWeekIdentifierInt(item.year, item.week)}`);
            for (const w of expectedWeeks) {
                storeData[w] = grouped[w] ? grouped[w] : [];
            }

            return {
                ...state,
                productTypeCapacityAllocationsLoading: false,
                productTypeCapacityAllocations: storeData,
            };
        }
        case CAPACITY_RESERVATIONS_LOADING:
            return {
                ...state,
                loadingCapacityReservations: true,
            };
        case CAPACITY_RESERVATIONS_LOADED: {
            const reservations = action.reservations.map((r) => {
                return new CapacityReservation(r);
            });
            return {
                ...state,
                loadingCapacityReservations: false,
                capacityReservations: reservations,
            };
        }
        case CAPACITY_RESERVATIONS_SAVING:
            return {
                ...state,
                savingCapacityReservations: true,
            };
        case CAPACITY_RESERVATIONS_SAVED: {
            return {
                ...state,
                savingCapacityReservations: false,
            };
        }
        case CAPACITY_RESERVATIONS_SAVE_FAIL:
            return {
                ...state,
                savingCapacityReservations: false,
            };
        case CAPACITY_ALLOCATIONS_SAVING:
        case CAPACITY_ALLOCATIONS_SAVE_FAIL:
            return state;
        case CAPACITY_ALLOCATIONS_SAVED: {
            const storeData = cloneDeep(state.capacityAllocationsByWeek);
            const allocations = action.data.map((a) => {
                return new CapacityAllocation(a);
            });
            for (const a of allocations) {
                const week = storeData[formatWeekIdentifierInt(a.year, a.week)];
                const existingInd = week.findIndex((alloc) => alloc.portfolioId === a.portfolioId);
                if (existingInd !== -1) {
                    week.splice(existingInd, 1);
                }
                week.push(a);
            }
            return {
                ...state,
                loadingCapacityData: false,
                capacityAllocationsByWeek: storeData,
            };
        }
        case PROD_CAPACITY_ALLOC_DATA_SAVING_PROD_TYPE:
        case PROD_CAPACITY_ALLOC_DATA_SAVE_FAILED_PROD_TYPE:
            return state;
        case PROD_CAPACITY_ALLOC_DATA_SAVED_PROD_TYPE: {
            const storeData = cloneDeep(state.productTypeCapacityAllocations);
            const allocations = action.data.map((a) => {
                return new CapacityGroupCapacityAllocation(a);
            });
            for (const a of allocations) {
                const week = storeData[formatWeekIdentifierInt(a.year, a.week)];
                const existingInd = week.findIndex((alloc) => alloc.capacityGroup.id === a.capacityGroup.id);
                if (existingInd !== -1) {
                    week.splice(existingInd, 1);
                }
                week.push(a);
            }
            return {
                ...state,
                productTypeCapacityAllocationsLoading: false,
                productTypeCapacityAllocations: storeData,
            };
        }
        case PROD_AVAILABLE_CAPA_LOADING: {
            return {
                ...state,
                productTypeAvailableCapacityLoading: true,
            };
        }
        case PROD_AVAILABLE_CAPA_LOADED: {
            const newCapa = [];
            for (const c of action.data) {
                newCapa.push(new AvailableCapacityPerDayAndProductType(c));
            }
            return {
                ...state,
                productTypeAvailableCapacity: newCapa,
                productTypeAvailableCapacityLoading: false,
            };
        }
        case PROD_AVAILABLE_CAPA_LOAD_FAILED: {
            return {
                ...state,
                productTypeAvailableCapacityLoading: false,
            };
        }
        case PROD_CAPACITY_PRODUCT_TYPES_LOADING: {
            return {
                ...state,
                productTypesLoading: true,
            };
        }
        case PROD_CAPACITY_PRODUCT_TYPES_LOADED: {
            const prodTypes = [];
            for (const t of action.productTypes) {
                prodTypes.push(new ProductType(t));
            }
            return {
                ...state,
                productTypesLoading: false,
                productTypes: prodTypes,
            };
        }
        case PROD_CAPACITY_CAPACITY_GROUPS_LOADING: {
            return {
                ...state,
                capacityGroupsLoading: true,
            };
        }
        case PROD_CAPACITY_CAPACITY_GROUPS_LOADED: {
            const capacityGroups = [];
            for (const t of action.capacityGroups) {
                capacityGroups.push(new CapacityGroup(t));
            }
            return {
                ...state,
                capacityGroupsLoading: false,
                capacityGroups: capacityGroups,
            };
        }
        case PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVING:
        case PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVE_FAIL:
        case PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVED:
            return state;
        case LOGGED_OFF:
            return initialState;
        default:
            return state;
    }
};

export const loadCapacityReservations = () => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_LOADING,
        });
        try {
            const start = moment().add(-2, 'month');
            const end = moment().add(12, 'month');
            const resp = await Ajax.get(`api/prodcapacity/reservations/${start.format()}/${end.format()}`);
            dispatch({
                type: CAPACITY_RESERVATIONS_LOADED,
                reservations: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const loadCapacityAllocations = (start, end) => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_DATA_LOADING,
        });
        try {
            let resp;
            const _start = moment().add(-2, 'month');
            const _end = moment().add(12, 'month');
            if (start && end) {
                resp = await Ajax.get(`api/prodcapacity/allocations/${start.format()}/${end.format()}`);
            } else {
                resp = await Ajax.get(`api/prodcapacity/allocations/${_start.format()}/${_end.format()}`);
            }

            dispatch({
                type: PROD_CAPACITY_DATA_LOADED,
                data: resp.data,
                startWeek: start ? formatWeekIdentifierMoment(start) : formatWeekIdentifierMoment(_start),
                endWeek: end ? formatWeekIdentifierMoment(end) : formatWeekIdentifierMoment(_end),
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const saveCapacityReservations = (reservations) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_SAVING,
        });
        try {
            await Ajax.post('api/prodcapacity/reservations/', reservations);
            loadCapacityReservations()(dispatch);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVED,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const saveCapacityAllocations = (allocations) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_ALLOCATIONS_SAVING,
        });
        try {
            await Ajax.post('api/prodcapacity/allocations/', allocations);
            dispatch({
                type: CAPACITY_ALLOCATIONS_SAVED,
                data: allocations,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_ALLOCATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const removeCapacityReservationsForOrder = (orderId) => {
    return async (dispatch) => {
        dispatch({
            type: CAPACITY_RESERVATIONS_SAVING,
        });
        try {
            await Ajax.delete(`api/prodcapacity/reservations/${orderId}`);
            loadCapacityReservations()(dispatch);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVED,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: CAPACITY_RESERVATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const productTypeLoadCapacityAllocations = (start, end) => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_ALLOC_DATA_LOADING_PROD_TYPE,
        });
        try {
            let resp;
            const _start = moment().add(-2, 'month');
            const _end = moment().add(12, 'month');
            if (start && end) {
                resp = await Ajax.get(`api/prodcapacity/allocationsproducttype/${start.format()}/${end.format()}`);
            } else {
                resp = await Ajax.get(`api/prodcapacity/allocationsproducttype/${_start.format()}/${_end.format()}`);
            }

            dispatch({
                type: PROD_CAPACITY_ALLOC_DATA_LOADED_PROD_TYPE,
                data: resp.data,
                startWeek: start ? formatWeekIdentifierMoment(start) : formatWeekIdentifierMoment(_start),
                endWeek: end ? formatWeekIdentifierMoment(end) : formatWeekIdentifierMoment(_end),
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const productTypeSaveCapacityAllocations = (allocations) => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_ALLOC_DATA_SAVING_PROD_TYPE,
        });
        try {
            await Ajax.post('api/prodcapacity/allocationsproducttype/', allocations);
            dispatch({
                type: PROD_CAPACITY_ALLOC_DATA_SAVED_PROD_TYPE,
                data: allocations,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: PROD_CAPACITY_ALLOC_DATA_SAVE_FAILED_PROD_TYPE,
            });
            return false;
        }
    };
};

export const getAvailableDailyCapacities = () => {
    return async (dispatch) => {
        dispatch({
            type: PROD_AVAILABLE_CAPA_LOADING,
        });
        try {
            const resp = await Ajax.get('api/prodcapacity/freecapacityperproducttype/');
            dispatch({
                type: PROD_AVAILABLE_CAPA_LOADED,
                data: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const getCapaNeeds = (orderId) => {
    return async (dispatch) => {
        const resp = await Ajax.get(`api/prodcapacity/capacityneeds/${orderId}`);
        return resp.data;
    };
};

export const getEarliestDeliveryDate = async (orderIds) => {
    const resp = await Ajax.get(`api/prodcapacity/earliestdeliverydate/${orderIds.join(',')}`);
    return resp.data;
};

export const makeCapacityReservations = async (orderIds) => {
    await Ajax.post(`api/prodcapacity/producttypemakecapacityreservations/${orderIds.join(',')}`);
};

export const updateCapacityReservations = async (orderIds) => {
    await Ajax.post(`api/prodcapacity/producttypeupdatecapacityreservations/${orderIds.join(',')}`);
};

export const getProductTypeCapacityReport = async () => {
    const resp = await Ajax.get('api/prodcapacity/producttypecapacityreport');
    return resp.data;
};

export const getEarliestDeliveryDateWithPayload = async (orders) => {
    const resp = await Ajax.post('api/prodcapacity/earliestdeliverydate', orders);
    return resp.data;
};

export const getProductTypes = () => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_PRODUCT_TYPES_LOADING,
        });
        try {
            const resp = await Ajax.get('api/prodcapacity/producttypes');
            dispatch({
                type: PROD_CAPACITY_PRODUCT_TYPES_LOADED,
                productTypes: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const refreshProductTypes = () => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_PRODUCT_TYPES_LOADING,
        });
        try {
            const resp = await Ajax.post('api/prodcapacity/producttypes/refresh');
            dispatch({
                type: PROD_CAPACITY_PRODUCT_TYPES_LOADED,
                productTypes: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};

export const upsertProductType = (productType) => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_PRODUCT_TYPES_LOADING,
        });
        try {
            const resp = await Ajax.post('api/prodcapacity/producttypes', productType);
            dispatch({
                type: PROD_CAPACITY_PRODUCT_TYPES_LOADED,
                productTypes: resp.data,
            });
        } catch (err) {
            console.log(err);
        }
    };
};

export const productTypeSaveCapacityReservations = (reservations) => {
    return async (dispatch) => {
        dispatch({
            type: PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVING,
        });
        try {
            await Ajax.post('api/prodcapacity/reservationsproducttype/', reservations);
            getAvailableDailyCapacities()(dispatch);
            dispatch({
                type: PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVED,
            });
            return true;
        } catch (err) {
            console.log(err);
            dispatch({
                type: PRODUCT_TYPE_CAPACITY_RESERVATIONS_SAVE_FAIL,
            });
            return false;
        }
    };
};

export const getCapacityGroups = () => {
    return async (dispatch) => {
        dispatch({
            type: PROD_CAPACITY_CAPACITY_GROUPS_LOADING,
        });
        try {
            const resp = await Ajax.get('api/prodcapacity/capacitygroups');
            dispatch({
                type: PROD_CAPACITY_CAPACITY_GROUPS_LOADED,
                capacityGroups: resp.data,
            });
            return resp.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    };
};
