import React, { useCallback, useEffect, useState, useRef } from 'react';
import Order from '../domain/order';
import { Grid, Checkbox } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FilterOrder from './filterorder';
import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment';
import AddressEditDialog from '../common/addresseditdialog';
import { formatCurrency } from '../common/common';
import CustomerAutoComplete from '../common/customerautocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { getCustomerFull } from '../modules/customerdata';
import Customer from '../domain/customer';
import DeliveryDatePicker from './deliveryDatePicker';
import OrderTypeSelector from './ordertypeselector';
import { makeStyles } from '@material-ui/core/styles';
import DeleteConfirmDialog from '../common/deleteconfirmdialog';
import { useDispatch } from 'react-redux';
import { filter, some, find, clone, sumBy, flatten } from 'lodash';
import EmailInput from '../common/emailinput';
import ParameterSelector from '../common/parameterselector';
import DeleteButton from '../common/deletebutton';
import ConfirmationDialog from '../common/confirmationdialog';
import Logger from '../common/logger';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import FilterProductSelectDialog from '../location/filterproductselectdialog';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
var isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(utc);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        marginBottom: '0.5em',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    table: {
        paddingTop: '2em',
        paddingBottom: '2em',
        width: '100%',
    },
    tableCellBold: {
        fontWeight: 'bold',
    },
    tableCellLocationName: {
        background: 'lightgray',
    },
    checkbox: {
        padding: '0px',
    },
    checkboxLocation: {
        padding: '0px 0px 0px 2px',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'table-row',
        },
    },
    sectionMobile: {
        display: 'table-row',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    addProdBtn: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    addressError: {
        color: 'red',
    },
}));

let fieldEditTimer = null;

export default function OrderItem(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    //single "main" order if multiorder edit
    const [order, setOrder] = useState(props.orders[0]);
    const [orderChanges, setOrderChanges] = useState({
        deliveryTime: props.orders[0].deliveryTime,
        earlyDeliveryTime: props.orders[0].earlyDeliveryTime,
        deliveryTerms: props.orders[0].deliveryTerms,
        ordererName: props.orders[0].ordererName,
        ordererTel: props.orders[0].ordererTel,
        reference: props.orders[0].reference,
        confirmationEmail: props.orders[0].confirmationEmail,
        packingInstructions: props.orders[0].packingInstructions,
        orderType: props.orders[0].orderType,
        generalInfo: props.orders[0].generalInfo,
        deliveryMethod: props.orders[0].deliveryMethod,
    });
    const [confirmDeleteRowsOpen, setConfirmDeleteRowsOpen] = useState(false);
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [removingMe, setRemovingMe] = useState(false);
    const [filterCounts, setFilterCounts] = useState({});
    const [addressEditOpen, setAddressEditOpen] = useState(false);
    const [customerSearchOpen, setCustomerSearchOpen] = useState(false);
    const [selectedDeliveryCustomer, setSelectedDeliveryCustomer] = useState(null);
    const [ignoreDateTimeChangeTimeoutHandle, setIgnoreDateTimeChangeTimeoutHandle] = useState(null);
    const [rowInEditId, setRowInEditId] = useState(null);
    const [ordersSelected, setOrdersSelected] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const [confirmDeliveryTimeResetOpen, setConfirmDeliveryTimeResetOpen] = useState(false);
    const [addNewProdDialogOpen, setAddNewProdDialogOpen] = useState(false);
    const [addProdToOrderId, setAddProdToOrderId] = useState(null);
    const [isAddressSelected, setIsAddressSelected] = useState(true);
    const orderChangesRef = useRef(null);
    orderChangesRef.current = orderChanges;
    const selectedRowsRef = useRef({});
    selectedRowsRef.current = selectedRows;
    const orders = props.orders;
    const updateOrderFormState = props.updateOrderFormState;
    const saveOrder = props.saveOrder;
    const updateCapacityReservationsIfNeeded = props.updateCapacityReservationsIfNeeded;
    const { t } = useTranslation();

    const getInputErrors = useCallback(
        (values) => {
            const status = {
                toimitusaika:
                    values.deliveryTime == null ||
                    values.deliveryTime.length === 0 ||
                    !moment(values.deliveryTime).isValid() ||
                    moment(values.deliveryTime).isBefore(moment(props.earliestDeliveryDate), 'day'),
                aikainentoimitusaika:
                    values.orderType !== Order.OrderTypeUrgent() &&
                    (values.earlyDeliveryTime == null ||
                        values.earlyDeliveryTime.length === 0 ||
                        !moment(values.earlyDeliveryTime).isValid() ||
                        moment(values.earlyDeliveryTime).isAfter(moment(values.deliveryTime), 'day')),
                toimitusehdot: values.deliveryTerms == null || values.deliveryTerms.length === 0,
                'tilaaja-nimi': values.ordererName == null || values.ordererName.length === 0,
                'tilaaja-puh': values.ordererTel == null || values.ordererTel.length === 0,
                'order-reference': values.reference == null || values.reference.length === 0,
                'confirmation-email': values.confirmationEmail == null || values.confirmationEmail.length === 0,
                deliveryMethod: values.deliveryMethod == null || values.deliveryMethod.length === 0,
                deliveryAddress: !isAddressSelected,
            };
            return status;
        },
        [props.earliestDeliveryDate, isAddressSelected]
    );

    const isFormOk = useCallback(() => {
        //checks common fields, first order in multiorder edit
        const status = getInputErrors(orderChanges);
        for (const k of Object.keys(status)) {
            if (status[k]) {
                return false;
            }
        }
        return true;
    }, [getInputErrors, orderChanges]);

    useEffect(() => {
        setIsAddressSelected(!order.deliveryAddressMustBeSelectedInCart);
    }, [order.deliveryAddressMustBeSelectedInCart]);

    useEffect(() => {
        //fired on mount and on unmount
        let fieldEditTimer = null;
        updateOrderFormState(isFormOk(), order.id);
        let counts = {};
        let select = {};
        //calculate from all orders
        for (let o of orders) {
            for (let f of o.orderedFilters) {
                counts[f.id] = f.count;
            }
            select[o.id] = false;
        }
        setFilterCounts(counts);
        setOrdersSelected(select);
        return () => {
            clearTimeout(fieldEditTimer);
        };
    }, [isFormOk, order.id, orders, updateOrderFormState]);

    useEffect(() => {
        if (
            order.deliveryTime !== props.orders[0].deliveryTime ||
            order.earlyDeliveryTime !== props.orders[0].earlyDeliveryTime ||
            order.deliveryTerms !== props.orders[0].deliveryTerms ||
            order.ordererName !== props.orders[0].ordererName ||
            order.ordererTel !== props.orders[0].ordererTel ||
            order.reference !== props.orders[0].reference ||
            order.confirmationEmail !== props.orders[0].confirmationEmail ||
            order.packingInstructions !== props.orders[0].packingInstructions ||
            order.orderType !== props.orders[0].orderType ||
            order.generalInfo !== props.orders[0].generalInfo ||
            order.deliveryMethod !== props.orders[0].deliveryMethod
        ) {
            const newOrderChanges = {
                deliveryTime: props.orders[0].deliveryTime,
                earlyDeliveryTime: props.orders[0].earlyDeliveryTime,
                deliveryTerms: props.orders[0].deliveryTerms,
                ordererName: props.orders[0].ordererName,
                ordererTel: props.orders[0].ordererTel,
                reference: props.orders[0].reference,
                confirmationEmail: props.orders[0].confirmationEmail,
                packingInstructions: props.orders[0].packingInstructions,
                orderType: props.orders[0].orderType,
                generalInfo: props.orders[0].generalInfo,
                deliveryMethod: props.orders[0].deliveryMethod,
            };
            setOrderChanges(newOrderChanges);
            updateOrderFormState(isFormOk(), props.orders[0].id);
        } else if (props.editOrderedItem) {
            updateOrderFormState(isFormOk(), props.orders[0].id);
        }
        setOrder(props.orders[0]);
    }, [props.orders, order, isFormOk, updateOrderFormState, props.editOrderedItem]);

    const saveNewAddress = useCallback(
        async (address) => {
            // only for delivery address for now.
            let newOrder = new Order(order);
            newOrder.deliveryAddress = address;
            newOrder.deliveryRecipient = !!address.name ? address.name : newOrder.deliveryRecipient;
            setAddressEditOpen(false);
            setCustomerSearchOpen(false);
            saveOrder(newOrder);
        },
        [order, saveOrder]
    );

    useEffect(() => {
        if (selectedDeliveryCustomer !== null) {
            let customer = new Customer(props.customersById[selectedDeliveryCustomer]);
            let deliveryAddress = customer.deliveryAddress;
            if (!deliveryAddress.name) {
                deliveryAddress.name = customer.name;
            }
            saveNewAddress(deliveryAddress);
            setSelectedDeliveryCustomer(null);
        }
    }, [props.customersById, saveNewAddress, selectedDeliveryCustomer]);

    useEffect(() => {
        if (props.savingOrder) {
            //setSelectedRows({});
        }
    }, [props.savingOrder]);

    useEffect(() => {
        async function checkDeliveryDateValidity() {
            if (props.earliestDeliveryDate) {
                if (props.orders[0].deliveryTime && moment(props.orders[0].deliveryTime).isValid()) {
                    if (moment(props.orders[0].deliveryTime).isBefore(moment(props.earliestDeliveryDate), 'day')) {
                        setConfirmDeliveryTimeResetOpen(true);
                    } else if (!props.editOrderedItem) {
                        // We do not do this automated update in case its order edit case
                        // When editing order, we only enforce that deliverytime stays valid. The actual capacity
                        // reservations are done when submitting the order edit.
                        if (
                            !(await updateCapacityReservationsIfNeeded(
                                props.orders[0],
                                moment(props.orders[0].deliveryTime)
                            ))
                        ) {
                            Logger.LogError('Failed to update capacityreservations, orderitem.jsx');
                        }
                    }
                }
            } else if (props.orders[0].deliveryTime) {
                setConfirmDeliveryTimeResetOpen(true);
            }
        }
        if (props.earliestDeliveryDateAvailable) checkDeliveryDateValidity();
    }, [
        props.earliestDeliveryDate,
        props.earliestDeliveryDateDetails,
        props.earliestDeliveryDateAvailable,
        props.orders,
        props.editOrderedItem,
        updateCapacityReservationsIfNeeded,
    ]);

    const getFilterCount = (fId) => {
        if (filterCounts[fId] == null) return 0;
        return filterCounts[fId];
    };

    const removeMe = () => {
        setRemovingMe(true);
        props.removeOrder(props.orders[0].id);
    };

    const onOpenDeliveryDatePicker = () => {
        setIgnoreDateTimeChangeTimeoutHandle(
            setTimeout(() => {
                setIgnoreDateTimeChangeTimeoutHandle(null);
            }, 500)
        );
    };

    const onOrderEditDateChange = (date) => {
        const selectedDate = moment(date);
        let newDate;
        let earlyDate = orderChanges.earlyDeliveryTime;
        if (ignoreDateTimeChangeTimeoutHandle) {
            return;
        }
        if (selectedDate.isValid) {
            newDate = selectedDate.hour(12).minute(0).second(0);
        } else {
            newDate = null;
        }
        if (newDate === null) {
            earlyDate = null;
        } else if (moment(earlyDate).isAfter(moment(newDate), 'day')) {
            earlyDate = newDate;
        }
        setOrderChanges({ ...orderChanges, deliveryTime: newDate, earlyDeliveryTime: earlyDate });
        clearTimeout(fieldEditTimer);
        fieldEditTimer = setTimeout(() => {
            saveChanges();
        }, 1000);
    };

    const onEarlyDeliveryDateChange = (date) => {
        const selectedDate = moment(date);
        let newDate = null;
        if (ignoreDateTimeChangeTimeoutHandle) {
            return;
        }
        if (selectedDate.isValid()) {
            newDate = selectedDate.hour(12).minute(0).second(0);
            if (moment(newDate).isAfter(moment(orderChanges.deliveryTime))) {
                toast.error(t('order.earliestDeliveryDateCannotByLaterThanDeliveryDate'), { autoClose: 5000 });
                newDate = null;
            }
        }
        setOrderChanges({ ...orderChanges, earlyDeliveryTime: newDate });
        clearTimeout(fieldEditTimer);
        fieldEditTimer = setTimeout(() => {
            saveChanges();
        }, 1000);
    };

    const onDateChange = async (date) => {
        // Make change/reservation to delivery time only when user selects it
        // This filters out the automatic callback by the date picker
        let newDate = null;
        let earlyDate = orderChanges.earlyDeliveryTime;
        const selectedDate = dayjs(date).hour(12);
        if (ignoreDateTimeChangeTimeoutHandle) {
            return;
        }

        if (selectedDate.isValid()) {
            newDate = selectedDate;
            if (selectedDate.isSameOrAfter(dayjs(props.earliestDeliveryDate), 'day')) {
                if (!(await props.makeCapacityReservations(selectedDate.utc().toISOString(), order))) {
                    toast.error(t('order.deliveryDateCouldNotBeSetCapacityReservationError'), {
                        autoClose: 5000,
                    });
                    newDate = null;
                }
            } else if (props.earliestDeliveryDate && dayjs(props.earliestDeliveryDate).isValid()) {
                // invalid date inputted into input box etc.
                toast.error(
                    t('order.earliestPossibleDeliveryDateIs', {
                        earliestDate: dayjs(props.earliestDeliveryDate).format('DD.MM.YYYY'),
                    }),
                    { autoClose: 5000 }
                );
                newDate = null;
            } else {
                toast.error(t('order.unspecifiedErrorInSettingDeliveryDate'), { autoClose: 5000 });
                newDate = null;
            }
        } else {
            newDate = null;
        }

        if (props.customerView) {
            earlyDate = newDate;
        } else if (newDate === null) {
            earlyDate = null;
        } else if (dayjs(earlyDate).isSameOrAfter(dayjs(newDate), 'day')) {
            earlyDate = newDate;
        }

        let deliveryTime = newDate ? newDate : null;
        if (deliveryTime && deliveryTime instanceof dayjs) {
            deliveryTime = deliveryTime.utc().toISOString();
        }
        let earlyDeliveryTime = earlyDate ? earlyDate : null;
        if (earlyDeliveryTime && earlyDate instanceof dayjs) {
            earlyDeliveryTime = earlyDeliveryTime.utc().toISOString();
        }
        setOrderChanges({
            ...orderChanges,
            deliveryTime: deliveryTime,
            earlyDeliveryTime: earlyDeliveryTime,
        });
        clearTimeout(fieldEditTimer);
        fieldEditTimer = setTimeout(() => {
            saveChanges();
        }, 1000);
    };

    const onChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        switch (name) {
            case 'toimitusehdot':
                setOrderChanges({ ...orderChanges, deliveryTerms: value });
                break;
            case 'tilaaja-nimi':
                setOrderChanges({ ...orderChanges, ordererName: value });
                break;
            case 'tilaaja-puh':
                setOrderChanges({ ...orderChanges, ordererTel: value });
                break;
            case 'order-reference':
                setOrderChanges({ ...orderChanges, reference: value });
                break;
            case 'order-packinginstructions':
                setOrderChanges({ ...orderChanges, packingInstructions: value });
                break;
            case 'OrderType':
                setOrderChanges({ ...orderChanges, orderType: value, deliveryTime: null, earlyDeliveryTime: null });
                break;
            case 'order-generalinfo':
                setOrderChanges({ ...orderChanges, generalInfo: value });
                break;
            case 'confirmation-email':
                setOrderChanges({ ...orderChanges, confirmationEmail: value });
                break;
            case 'DeliveryMethod':
                const param = props.parameters['DeliveryMethod'].find((p) => p.id === value);
                setOrderChanges({
                    ...orderChanges,
                    deliveryMethod: param ? param.value : null,
                    deliveryTime: null,
                    earlyDeliveryTime: null,
                });
                break;
            default:
                break;
        }
        clearTimeout(fieldEditTimer);
        fieldEditTimer = setTimeout(() => {
            saveChanges();
        }, 1000);
    };

    const handleFilterCountChange = async (fId, count) => {
        if (!!fieldEditTimer) {
            clearTimeout(fieldEditTimer);
            await saveChanges();
        }
        if (count !== '' && count !== 0) {
            let counts = filterCounts;
            counts[fId] = count;
            setFilterCounts(counts);
        }
    };

    const handleFilterCountChangeDone = async (fId, count) => {
        let fOrderToUpdate = null;
        //search order by filterOrder id
        let newOrder = null;
        for (let o of props.orders) {
            if (some(o.orderedFilters, { id: fId })) {
                newOrder = new Order(o);
                break;
            }
        }
        for (let o of newOrder.orderedFilters) {
            if (o.id === fId) {
                o.count = count;
                fOrderToUpdate = o;
                break;
            }
        }
        if (fOrderToUpdate !== null) {
            await props.saveFilterOrder(newOrder, fOrderToUpdate);
        }
    };

    const saveChanges = async () => {
        fieldEditTimer = null;
        let newOrder = new Order(order);
        newOrder.deliveryTerms = orderChangesRef.current.deliveryTerms;
        newOrder.deliveryTime = orderChangesRef.current.deliveryTime;
        newOrder.earlyDeliveryTime = orderChangesRef.current.earlyDeliveryTime;
        newOrder.ordererName = orderChangesRef.current.ordererName;
        newOrder.ordererTel = orderChangesRef.current.ordererTel;
        newOrder.reference = orderChangesRef.current.reference;
        newOrder.confirmationEmail = orderChangesRef.current.confirmationEmail;
        newOrder.packingInstructions = orderChangesRef.current.packingInstructions;
        newOrder.orderType = orderChangesRef.current.orderType;
        newOrder.generalInfo = orderChangesRef.current.generalInfo;
        newOrder.deliveryMethod = orderChangesRef.current.deliveryMethod;
        await props.saveOrderShallow(newOrder);
    };

    const cancelDelete = () => {
        setConfirmDeleteRowsOpen(false);
    };

    const confirmDelete = async () => {
        const removeOrders = [];
        for (let ord of props.orders) {
            let newOrder = new Order(ord);
            let filtOrders = [];
            let doSave = false;
            let removeOrder = true;
            for (let o of newOrder.orderedFilters) {
                if (!rowIsSelected(o.id)) {
                    filtOrders.push(o);
                    removeOrder = false;
                } else {
                    doSave = true;
                }
            }
            if (removeOrder) removeOrders.push(ord.id);
            if (doSave) {
                newOrder.orderedFilters = filtOrders;
                await props.saveOrder(newOrder);
            }
        }
        if (removeOrders.length) {
            removeOrders.forEach((ordId) => props.removeOrder(ordId));
        }
        setConfirmDeleteRowsOpen(false);
        setSelectedRows({});
    };

    const onClickSelectAllRows = (e) => {
        let target = e.target;
        let newSelectedRows = clone(selectedRows);
        const orderedFiltersIds = flatten(props.orders.map((o) => o.orderedFilters)).map((o) => o.id);
        for (let fId of orderedFiltersIds) {
            newSelectedRows[fId] = target.checked;
        }
        let select = {};
        //calculate from all orders
        for (let o of props.orders) {
            select[o.id] = target.checked;
        }
        setOrdersSelected(select);
        setSelectedRows(newSelectedRows);
        setAllSelected(!allSelected);
        setRowInEditId(null);
    };

    const onClickSelectOrderAllRows = (e, orderId) => {
        let target = e.target;
        let newSelectedRows = clone(selectedRows);
        const targetOrder = find(props.orders, { id: orderId });
        const orderedFiltersIds = targetOrder.orderedFilters.map((o) => o.id);
        for (let fId of orderedFiltersIds) {
            newSelectedRows[fId] = target.checked;
        }
        setSelectedRows(newSelectedRows);
        setRowInEditId(null);
        let newOrderRowsSelected = clone(ordersSelected);
        newOrderRowsSelected[orderId] = target.checked;
        setOrdersSelected(newOrderRowsSelected);
    };

    const rowIsSelected = (id) => {
        return selectedRows[id] != null && selectedRows[id] === true;
    };

    const allOrdersIsSelected = () => {
        for (let o of props.orders) {
            if (ordersSelected[o.id] === undefined || ordersSelected[o.id] !== true) return false;
        }
        return true;
    };

    const onClickRow = (id, editAllowed) => {
        //if (editAllowed) setRowInEditId(id);
        setRowInEditId(id);
    };

    const filterOrderChanged = async (newFo) => {
        let fOrderToUpdate = null;
        let newOrder = null;
        for (let o of props.orders) {
            if (some(o.orderedFilters, { id: newFo.id })) {
                newOrder = new Order(o);
                break;
            }
        }
        for (let o of newOrder.orderedFilters) {
            if (o.id === newFo.id) {
                o.machineName = newFo.machineName;
                o.machineLocation = newFo.machineLocation;
                o.machineArea = newFo.machineArea;
                o.unitPriceWithoutVat = newFo.unitPriceWithoutVat;
                o.palletInformation = newFo.palletInformation;
                fOrderToUpdate = o;
            }
        }
        if (fOrderToUpdate) await props.saveFilterOrder(newOrder, fOrderToUpdate);
    };

    const onClickSelect = (id) => {
        const newSelectedRows = Object.assign({}, selectedRowsRef.current);

        newSelectedRows[id] = !newSelectedRows[id];
        setSelectedRows(newSelectedRows);
        let newOrderRowsSelected = clone(ordersSelected);
        for (let ord of props.orders) {
            if (some(ord.orderedFilters, { id: id })) {
                if (ord.orderedFilters.every((filtOrd) => newSelectedRows[filtOrd.id] === newSelectedRows[id])) {
                    newOrderRowsSelected[ord.id] = newSelectedRows[id];
                } else {
                    newOrderRowsSelected[ord.id] = false;
                }
                setOrdersSelected(newOrderRowsSelected);
            }
        }
    };

    const closeRowEdit = () => {
        setRowInEditId(null);
    };

    const removeSelectedRows = () => {
        let notAllowed = true;

        for (let ord of props.orders) {
            for (let o of ord.orderedFilters) {
                if (!rowIsSelected(o.id)) {
                    notAllowed = false;
                }
            }
            if (!props.editOrderedItem && notAllowed) break;
        }

        if (notAllowed) {
            setAlertDialogOpen(true);
        } else {
            setConfirmDeleteRowsOpen(true);
        }
    };

    const anyRowFromOrderSelected = (skipDeliveryFeeRows = false) => {
        let orderedFiltersIds = [];
        for (let ord of props.orders) {
            if (skipDeliveryFeeRows)
                orderedFiltersIds.push(
                    ord.orderedFilters
                        .filter(
                            (o) => o.productBundle || (o.product.productCode !== 'R' && o.product.productCode !== 'RP')
                        )
                        .map((o) => o.id)
                );
            else orderedFiltersIds.push(ord.orderedFilters.map((o) => o.id));
        }
        orderedFiltersIds = flatten(orderedFiltersIds);
        for (let fId of orderedFiltersIds) {
            if (selectedRows[fId] === true) return true;
        }
        return false;
    };

    const editDeliveryAddress = () => {
        if (!order.deliveryAddress.name) {
            order.deliveryAddress.name = order.deliveryRecipient;
        }
        setAddressEditOpen(true);
        setCustomerSearchOpen(false);
    };

    const searchCustomer = () => {
        setCustomerSearchOpen(!customerSearchOpen);
    };

    const getCustomerFullData = async (customerId) => {
        if (!props.customersById[customerId]) await dispatch(getCustomerFull(customerId));
    };

    const customerSelectedInternal = async (customerId) => {
        if (!props.customersById[customerId]) {
            setSelectedDeliveryCustomer(customerId);
            await getCustomerFullData(customerId);
        } else {
            let customer = new Customer(props.customersById[customerId]);
            let deliveryAddress = customer.deliveryAddress;
            if (!deliveryAddress.name) {
                deliveryAddress.name = customer.name;
            }
            saveNewAddress(deliveryAddress);
        }
    };

    const customerSelected = (customerId) => {
        customerSelectedInternal(customerId);
    };

    const getParametersObject = (type, text) => {
        return props.parameters[type].find((p) => p.value === text);
    };

    const onEditPalletInfo = () => {
        props.editFoPalletInfo(order.orderedFilters.filter((fo) => rowIsSelected(fo.id)).map((fo) => fo.id));
    };

    const customersWithDeliveryInfo =
        props.customers !== null && props.customers.length > 0
            ? filter(props.customers, (c) => c != null && c.deliveryAddressId != null)
            : [];

    const addDelivery = () => {
        props.addProductWithCode(orders[0].id, 'R');
    };

    const addDeliveryAndPackagingContractPrice = () => {
        props.addProductWithCode(orders[0].id, 'RP');
    };
    const productSelected = (prodId) => {
        props.addProductWithId(addProdToOrderId, prodId);
        setAddNewProdDialogOpen(false);
        setAddProdToOrderId(null);
    };
    const addProduct = (orderId) => {
        setAddProdToOrderId(orderId);
        setAddNewProdDialogOpen(true);
    };

    const ordersTotalPrice = () => {
        return sumBy(props.orders, function (o) {
            return o.orderTotalPrice;
        });
    };

    const ordersVatAmount = () => {
        return sumBy(props.orders, function (o) {
            return o.vatAmount;
        });
    };

    const ordersTotalPriceWithoutVat = () => {
        return sumBy(props.orders, function (o) {
            return o.orderTotalPriceWithoutVat;
        });
    };

    const isChecked = (orderId) => {
        if (ordersSelected[orderId]) return ordersSelected[orderId];
        return false;
    };

    const confirmResetDeliveryTime = async () => {
        setOrderChanges({ ...orderChanges, deliveryTime: null });
        saveChanges();

        if (!props.editOrderedItem) {
            const promises = props.orders.map((o) => props.removeCapacityReservations(o.id));
            Promise.all(promises);
        }

        setTimeout(() => {
            setConfirmDeliveryTimeResetOpen(false);
        }, 200);
    };

    const alertDialogConfirm = () => {
        setAlertDialogOpen(false);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12}>
                <Grid container>
                    {!props.editOrderedItem && (
                        <Grid item xs={12}>
                            <DeleteButton
                                onSubmit={removeMe}
                                name="remove-order"
                                deleting={removingMe}
                                disabled={removingMe}
                            />
                        </Grid>
                    )}
                    {!props.customerView && (
                        <Grid item container>
                            <Grid item xs={12} md={4}>
                                <strong>{t('general.portfolio')}</strong>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {props.portfolio.name}
                            </Grid>
                        </Grid>
                    )}

                    {props.editOrderedItem && (
                        <Grid item container>
                            <Grid item xs={12} md={4}>
                                <strong>{t('general.orderNumber')}</strong>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {order.orderNumber}
                            </Grid>
                        </Grid>
                    )}

                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('general.businessId')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.billingResponsibleBusinessId}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('general.vatId')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.billingResponsibleVatId}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('general.customerNumber')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.billingResponsibleCustomerNumber}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('parameterCategories.ContractNumber')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.contractNumber}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('parameterCategories.OurReference')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.ourReference}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('parameterCategories.InvoicingTerms')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.billingTerms}
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('general.invoicingAddress')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            {order.billingResponsible}
                            {order.billingAddress && (
                                <div>
                                    {order.billingAddress.streetAddress},&nbsp;
                                    {order.billingAddress.zipCode}&nbsp;
                                    {order.billingAddress.city}
                                </div>
                            )}
                        </Grid>
                    </Grid>
                    {order.electronicInvoiceAddress && (
                        <Grid item container>
                            <Grid item xs={12} md={4}>
                                <strong>{t('general.eInvoiceAddress')}</strong>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                {order.electronicInvoiceAddress && (
                                    <div>
                                        {order.electronicInvoiceAddress.ovt},&nbsp;
                                        {order.electronicInvoiceAddress.operator},&nbsp;
                                        {order.electronicInvoiceAddress.operatorBrokerId}
                                    </div>
                                )}
                                {!order.electronicInvoiceAddress && <div>-</div>}
                            </Grid>
                        </Grid>
                    )}
                    {order.emailInvoiceAddress != null && order.emailInvoiceAddress.length > 0 && (
                        <Grid item container>
                            <Grid item xs={12} md={4}>
                                <strong>{t('invoice.emailInvoiceAddress')}</strong>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <div>{order.emailInvoiceAddress}</div>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item container>
                        <Grid item xs={12} md={4}>
                            <strong>{t('general.deliveryCustomerNumber')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <div>{order.deliveryCustomerNumber}</div>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={12} md={4} className={isAddressSelected ? {} : classes.addressError}>
                            <strong>{t('general.deliveryAddress')}</strong>
                        </Grid>
                        <Grid item xs={12} md={8} className={isAddressSelected ? {} : classes.addressError}>
                            {props.orders && props.orders.length > 1 && props.orders[0].orderToPrivateCustomer ? (
                                <span>...</span>
                            ) : (
                                <React.Fragment>
                                    <div>
                                        {order.deliveryRecipient}
                                        <br />
                                        {order.deliveryAddress.streetAddress},&nbsp;
                                        {order.deliveryAddress.zipCode}&nbsp;
                                        {order.deliveryAddress.city}
                                    </div>
                                    <span>
                                        <Tooltip title={t('order.editDeliveryAddress')}>
                                            <Button onClick={editDeliveryAddress}>
                                                <EditIcon />
                                            </Button>
                                        </Tooltip>
                                        {!props.customerView && (
                                            <Tooltip title={t('order.copyDeliveryAddressFromCustomer')}>
                                                <Button onClick={searchCustomer} name="btn-search-customer">
                                                    <SearchIcon />
                                                </Button>
                                            </Tooltip>
                                        )}
                                        {customerSearchOpen && (
                                            <CustomerAutoComplete
                                                customers={customersWithDeliveryInfo}
                                                selected={customerSelected}
                                            />
                                        )}
                                    </span>
                                </React.Fragment>
                            )}
                        </Grid>
                    </Grid>

                    {props.earliestDeliveryDateDetails && (
                        <Grid item xs={12} md={6}>
                            <h3>Toimitusajat</h3>
                            {props.earliestDeliveryDateDetails.map((del) => (
                                <div key={del.productType.id}>
                                    {del.earliestDeliveryDate && (
                                        <React.Fragment>
                                            {dayjs(del.earliestDeliveryDate).format('DD.MM.YYYY')}&nbsp;-&nbsp;
                                            {del.productType.name}
                                        </React.Fragment>
                                    )}
                                    {!del.earliestDeliveryDate && (
                                        <React.Fragment>
                                            <strong>Ei kapasiteettia</strong>&nbsp;
                                            {del.productType.name}
                                        </React.Fragment>
                                    )}
                                </div>
                            ))}
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <DeliveryDatePicker
                            label={t('general.deliveryDate') + ' *'}
                            value={orderChanges.deliveryTime}
                            onChange={props.editOrderedItem ? onOrderEditDateChange : onDateChange}
                            id={'toimitusaika-' + order.id}
                            earliestDeliveryDate={props.earliestDeliveryDate}
                            onOpen={onOpenDeliveryDatePicker}
                            name="deliveryTime"
                            deliveryMethod={orderChanges.deliveryMethod}
                        />
                    </Grid>

                    {!props.customerView && (
                        <Grid item xs={12} md={6}>
                            <OrderTypeSelector
                                handleChange={(value) => onChange({ target: { value, name: 'OrderType' } })}
                                selected={orderChanges.orderType}
                            />
                        </Grid>
                    )}

                    {!props.customerView && orderChanges.orderType !== Order.OrderTypeUrgent() && (
                        <Grid item xs={12} md={6}>
                            <DeliveryDatePicker
                                label={t('order.earliestDeliveryDate') + ' *'}
                                value={orderChanges.earlyDeliveryTime}
                                onChange={onEarlyDeliveryDateChange}
                                id={'aikaisintoimitusaika-' + order.id}
                                earliestDeliveryDate={props.earliestDeliveryDate}
                                latestDeliveryDate={orderChanges.deliveryTime}
                                onOpen={onOpenDeliveryDatePicker}
                                disabled={!moment(orderChanges.deliveryTime).isValid()}
                                name="earlyDeliveryTime"
                                enableAllWeekDays={true}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <ParameterSelector
                            values={props.parameters}
                            category={'DeliveryMethod'}
                            selected={getParametersObject('DeliveryMethod', orderChanges.deliveryMethod)}
                            handleChange={(type, value) => onChange({ target: { value, name: 'DeliveryMethod' } })}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            disabled={order.deliveryTermsImmutable === true}
                            required
                            error={getInputErrors(orderChanges)['toimitusehdot']}
                            id={'toimitusehdot-' + order.id}
                            name="toimitusehdot"
                            label={t('parameterCategories.DeliveryTerms')}
                            className={classes.textField}
                            margin="normal"
                            value={orderChanges.deliveryTerms}
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={getInputErrors(orderChanges)['tilaaja-nimi']}
                            id={'tilaaja-nimi-' + order.id}
                            name="tilaaja-nimi"
                            label={t('order.ordererName')}
                            value={orderChanges.ordererName}
                            className={classes.textField}
                            margin="normal"
                            onChange={onChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={getInputErrors(orderChanges)['tilaaja-puh']}
                            id={'tilaaja-puh-' + order.id}
                            name="tilaaja-puh"
                            label={t('order.deliveryRecipientTel')}
                            value={orderChanges.ordererTel}
                            className={classes.textField}
                            margin="normal"
                            onChange={onChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <EmailInput
                            required={true}
                            error={getInputErrors(orderChanges)['confirmation-email']}
                            name="confirmation-email"
                            onChange={(value) => {
                                onChange({
                                    target: {
                                        value: value,
                                        name: 'confirmation-email',
                                    },
                                });
                            }}
                            emails={orderChanges.confirmationEmail}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            error={getInputErrors(orderChanges)['order-reference']}
                            id={'order-reference-' + order.id}
                            name="order-reference"
                            label={t('general.yourReference')}
                            value={orderChanges.reference}
                            className={classes.textField}
                            margin="normal"
                            onChange={onChange}
                        />
                    </Grid>

                    {!props.customerView && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                multiline={true}
                                id={'order-packinginstructions-' + order.id}
                                name="order-packinginstructions"
                                label={t('general.packingInstructions')}
                                value={!!orderChanges.packingInstructions ? orderChanges.packingInstructions : ''}
                                className={classes.textField}
                                margin="normal"
                                onChange={onChange}
                            />
                        </Grid>
                    )}
                    {!props.customerView && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                multiline={true}
                                id={'order-generalinfo-' + order.id}
                                name="order-generalinfo"
                                label={t('order.generalInfoToProduction')}
                                value={!!orderChanges.generalInfo ? orderChanges.generalInfo : ''}
                                className={classes.textField}
                                margin="normal"
                                onChange={onChange}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} className={classes.table}>
                        <table className="table table-sm">
                            <thead>
                                {!props.customerView && (
                                    <tr className={classes.sectionDesktop}>
                                        <th>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={allSelected}
                                                indeterminate={allSelected === !allOrdersIsSelected()}
                                                onChange={(e) => onClickSelectAllRows(e)}
                                                name="check-all"
                                            />
                                        </th>
                                        <th>{t('location.machine')}</th>
                                        <th>{t('order.filter')}</th>
                                        <th>{t('pcs')}</th>
                                        <th>{t('order.pricePerUnitWithDiscountVat0')}</th>
                                        <th>{t('invoice.totalPriceVatZero')}</th>
                                    </tr>
                                )}
                                <tr className={classes.sectionMobile}>
                                    <th>
                                        <Checkbox
                                            className={classes.checkbox}
                                            checked={allSelected}
                                            indeterminate={allSelected === !allOrdersIsSelected()}
                                            onChange={(e) => onClickSelectAllRows(e)}
                                            name="check-all"
                                        />
                                    </th>
                                    <th>{t('order.filter')}</th>
                                </tr>
                                {props.customerView && (
                                    <tr className={classes.sectionDesktop}>
                                        <th>
                                            <Checkbox
                                                className={classes.checkbox}
                                                checked={allSelected}
                                                indeterminate={allSelected === !allOrdersIsSelected()}
                                                onChange={(e) => onClickSelectAllRows(e)}
                                                name="check-all"
                                            />
                                        </th>
                                        <th>{t('location.machine')}</th>
                                        <th>{t('order.filter')}</th>
                                        <th>{t('pcs')}</th>
                                        {props.allowPricing && (
                                            <React.Fragment>
                                                <th>{t('order.pricePerUnitVat0')}</th>
                                                <th>{t('invoice.totalPriceVatZero')}</th>
                                            </React.Fragment>
                                        )}
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {props.orders.map((orderitem) => (
                                    <React.Fragment key={orderitem.id}>
                                        {props.orders.length > 1 && (
                                            <tr>
                                                <td className={classes.tableCellLocationName}>
                                                    <Checkbox
                                                        className={classes.checkboxLocation}
                                                        checked={isChecked(orderitem.id)}
                                                        onChange={(e) => onClickSelectOrderAllRows(e, orderitem.id)}
                                                        name="edit-check-all"
                                                    />
                                                </td>
                                                <td colSpan={5} className={classes.tableCellLocationName}>
                                                    <strong>
                                                        {orderitem.location
                                                            ? orderitem.location.name
                                                            : orderitem.getDeliveryInfo()}
                                                    </strong>
                                                </td>
                                            </tr>
                                        )}
                                        {orderitem.orderedFilters
                                            .filter(function (f) {
                                                return f.product &&
                                                    (f.product.productCode === 'R' || f.product.productCode === 'RP')
                                                    ? false
                                                    : true;
                                            })
                                            .map((f, ind) => (
                                                <FilterOrder
                                                    index={ind}
                                                    key={f.id}
                                                    customerView={props.customerView}
                                                    filterOrder={f}
                                                    filterCount={getFilterCount(f.id)}
                                                    selected={selectedRows[f.id]}
                                                    clickRow={(id) => onClickRow(id, props.editOrderedItem)}
                                                    clickSelect={onClickSelect}
                                                    filterCountChange={handleFilterCountChange}
                                                    filterCountChangeDone={handleFilterCountChangeDone}
                                                    allowPricing={props.allowPricing}
                                                    editFoPalletInfo={props.editFoPalletInfo}
                                                    editOrderedItem={props.editOrderedItem}
                                                    editRow={rowInEditId === f.id}
                                                    fadeRow={!!rowInEditId}
                                                    closeRowEdit={closeRowEdit}
                                                    filterOrderChanged={filterOrderChanged}
                                                    priceOnly={false}
                                                    saving={props.savingOrder}
                                                />
                                            ))}
                                        <Grid item xs={12}>
                                            <Button
                                                className={classes.addProdBtn}
                                                variant="contained"
                                                color="secondary"
                                                name="addproduct"
                                                onClick={() => addProduct(orderitem.id)}
                                                startIcon={<AddBoxIcon />}>
                                                {t('general.product')}
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                ))}
                                {props.orders.map((orderitem) => (
                                    <React.Fragment key={orderitem.id}>
                                        {orderitem.orderedFilters
                                            .filter(function (f) {
                                                return !f.productBundle &&
                                                    (f.product.productCode === 'R' || f.product.productCode === 'RP')
                                                    ? true
                                                    : false;
                                            })
                                            .map((f) => (
                                                <FilterOrder
                                                    key={f.id}
                                                    customerView={props.customerView}
                                                    filterOrder={f}
                                                    filterCount={getFilterCount(f.id)}
                                                    selected={selectedRows[f.id]}
                                                    clickRow={(id) => onClickRow(id, true)}
                                                    clickSelect={onClickSelect}
                                                    filterCountChange={handleFilterCountChange}
                                                    filterCountChangeDone={handleFilterCountChangeDone}
                                                    allowPricing={props.allowPricing}
                                                    editRow={rowInEditId === f.id}
                                                    fadeRow={!!rowInEditId}
                                                    closeRowEdit={closeRowEdit}
                                                    filterOrderChanged={filterOrderChanged}
                                                    priceOnly={true}
                                                />
                                            ))}
                                    </React.Fragment>
                                ))}
                                <tr></tr>
                                {props.allowPricing && (
                                    <React.Fragment>
                                        <tr className={classes.sectionDesktop} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={classes.tableCellBold}>{t('invoice.totalPriceVatZero')}</td>
                                            <td className={classes.tableCellBold}>
                                                {formatCurrency(ordersTotalPriceWithoutVat())}
                                            </td>
                                        </tr>
                                        <tr className={classes.sectionDesktop} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={classes.tableCellBold}>
                                                {t('general.vatShort')} {order.vatPercent}%
                                            </td>
                                            <td className={classes.tableCellBold}>
                                                {formatCurrency(ordersVatAmount())}
                                            </td>
                                        </tr>
                                        <tr className={classes.sectionDesktop} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td className={classes.tableCellBold}>{t('invoice.totalPriceVatIncl')}</td>
                                            <td className={classes.tableCellBold}>
                                                {formatCurrency(ordersTotalPrice())}
                                            </td>
                                        </tr>
                                        <tr className={classes.sectionMobile}>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        <tr className={classes.sectionMobile} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td>
                                                {t('invoice.totalPriceVatZero')}:{' '}
                                                <strong>{formatCurrency(ordersTotalPriceWithoutVat())}</strong>
                                            </td>
                                        </tr>
                                        <tr className={classes.sectionMobile} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td>
                                                {t('general.vatShort')} {order.vatPercent}%:{' '}
                                                <strong>{formatCurrency(order.vatAmount)}</strong>
                                            </td>
                                        </tr>
                                        <tr className={classes.sectionMobile} style={{ fontWeight: 'bold' }}>
                                            <td></td>
                                            <td>
                                                {t('invoice.totalPriceVatIncl')}:{' '}
                                                <strong>{formatCurrency(ordersTotalPrice())}</strong>
                                            </td>
                                        </tr>
                                        {props.customerView && order.customer && order.customer.id === 184612 && (
                                            <tr>
                                                <td colSpan={2}>
                                                    <em>&#42;) Models so marked are not Eurovent certified </em>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                )}
                                <tr>
                                    <td colSpan="7">
                                        {anyRowFromOrderSelected() && (
                                            <Grid item container spacing={1}>
                                                <Grid item>
                                                    <DeleteButton
                                                        onSubmit={removeSelectedRows}
                                                        name={'btn-remove-selected'}
                                                    />
                                                </Grid>
                                                {!props.editOrderedItem && anyRowFromOrderSelected(true) && (
                                                    <Grid item>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<EditIcon />}
                                                            onClick={onEditPalletInfo}>
                                                            {t('order.palletGroup')}
                                                        </Button>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>

                    {!props.customerView && (
                        <Grid item xs={12} md={6}>
                            <Tooltip title={t('order.addFreight')}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    name="adddeliverycharge"
                                    onClick={addDelivery}
                                    startIcon={<AddBoxIcon />}>
                                    R
                                </Button>
                            </Tooltip>
                            &nbsp;
                            <Tooltip title={t('order.addFreightAndPackagingContractPrice')}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    name="adddeliverychargescontractprice"
                                    onClick={addDeliveryAndPackagingContractPrice}
                                    startIcon={<AddBoxIcon />}>
                                    RP
                                </Button>
                            </Tooltip>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <DeleteConfirmDialog
                open={confirmDeleteRowsOpen}
                itemToDelete={t('order.selectedRowsFromOrder')}
                confirmDelete={confirmDelete}
                cancel={cancelDelete}
            />
            <AddressEditDialog
                address={order.deliveryAddress}
                open={addressEditOpen}
                cancel={() => setAddressEditOpen(false)}
                save={saveNewAddress}
            />
            <ConfirmationDialog
                open={confirmDeliveryTimeResetOpen}
                confirm={confirmResetDeliveryTime}
                cancel={confirmResetDeliveryTime}
                confirmText={t('buttons.close')}
                hideCancel={true}>
                {t('order.notEnoughCapacityDueToChangeInFilters')}
            </ConfirmationDialog>
            <ConfirmationDialog
                open={alertDialogOpen}
                confirm={alertDialogConfirm}
                cancel={alertDialogConfirm}
                confirmText={t('buttons.close')}
                hideCancel={true}>
                {t('order.cannotRemoveAllRowsFromOrder')}
            </ConfirmationDialog>
            <FilterProductSelectDialog showBundles={true} show={addNewProdDialogOpen} selected={productSelected} />
        </Grid>
    );
}
