import Machine from './machine';
import Customer from './customer';
import Address from './address';
import ElectronicInvoiceInfo from './electronicinvoiceinfo';
import ObjectIdGenerator from '../modules/objectidgenerator';
import Order from './order';
import moment from 'moment';
import CustomershipStatus from './customershipstatus';
import Note from './note';
import ParameterValue from './parametervalue';
import PartyRelation from './partyrelation';
import { flatten, every, sortBy, sumBy, filter } from 'lodash';
import FilterProduct from '../domain/filterproduct';
import { Portfolio } from './portfolio';
import Logger from '../common/logger';
import { verifyEmailAddress } from '../common/common';
import Contract from './contract';

function sortBySendDate(a, b) {
    if (moment(a.orderSent).isBefore(moment(b.orderSent))) {
        return 1;
    }
    return -1;
}

class Location {
    constructor(jsonInput) {
        if (jsonInput != null) {
            this.id = jsonInput.id;
            this.storageId = jsonInput.storageId;
            this.name = jsonInput.name;
            this.businessId = jsonInput.businessId;
            this.customerNumber = jsonInput.customerNumber;
            if (jsonInput.address != null) this.address = new Address(jsonInput.address);
            this.locationNumber = jsonInput.locationNumber;

            if (jsonInput.contactPersonName !== null) this.contactPersonName = jsonInput.contactPersonName;
            else this.contactPersonName = '';

            if (jsonInput.contactPersonTel !== null) this.contactPersonTel = jsonInput.contactPersonTel;
            else this.contactPersonTel = '';

            this.additionalInfo = jsonInput.additionalInfo;

            if (jsonInput.deliveryCustomer != null) this.deliveryCustomer = new Customer(jsonInput.deliveryCustomer);
            if (jsonInput.deliveryAddress != null) this.deliveryAddress = new Address(jsonInput.deliveryAddress);
            if (jsonInput.customer != null) this.customer = new Customer(jsonInput.customer);
            if (jsonInput.billingAddress != null) this.billingAddress = new Address(jsonInput.billingAddress);
            if (jsonInput.electronicInvoiceAddress != null)
                this.electronicInvoiceAddress = new ElectronicInvoiceInfo(jsonInput.electronicInvoiceAddress);

            this.reminderMonths = jsonInput.reminderMonths;
            this.machines = [];

            if (jsonInput.machines != null && jsonInput.machines.length > 0) {
                for (let machine of jsonInput.machines) {
                    this.machines.push(new Machine(machine));
                }
            }
            if (jsonInput.contactCustomer != null) {
                this.contactCustomer = new Customer(jsonInput.contactCustomer);
            }
            this.useCustomerBillingAddress = jsonInput.useCustomerBillingAddress;
            this.useCustomerEInvoiceInfo = jsonInput.useCustomerEInvoiceInfo;
            this.orderValueWithoutVat = jsonInput.orderValueWithoutVat;
            this.latestOrders = [];
            if (jsonInput.latestOrders != null && jsonInput.latestOrders.length > 0) {
                for (let o of jsonInput.latestOrders) {
                    this.latestOrders.push(new Order(o));
                }
                this.latestOrders.sort(sortBySendDate);
            }

            if (jsonInput.billingCustomer != null) {
                this.billingCustomer = new Customer(jsonInput.billingCustomer);
                this.useBillingCustomerEInvoiceInfo = jsonInput.useBillingCustomerEInvoiceInfo;
                this.useBillingCustomerBillingAddress = jsonInput.useBillingCustomerBillingAddress;
            } else {
                this.billingCustomer = null;
                this.useBillingCustomerEInvoiceInfo = false;
                this.useBillingCustomerBillingAddress = false;
            }

            this.billingInformationValid = jsonInput.billingInformationValid;
            if (jsonInput.billingInformation != null) {
                this.billingInformation = {};
                this.billingInformation.billingResponsible = jsonInput.billingInformation.billingResponsible;
                if (jsonInput.billingInformation.billingAddress != null) {
                    this.billingInformation.billingAddress = new Address(jsonInput.billingInformation.billingAddress);
                }
                if (jsonInput.billingInformation.electronicInvoiceAddress != null) {
                    this.billingInformation.electronicInvoiceAddress = new ElectronicInvoiceInfo(
                        jsonInput.billingInformation.electronicInvoiceAddress
                    );
                }
                this.billingInformation.billingResponsibleContractNumber =
                    jsonInput.billingInformation.billingResponsibleContractNumber;
                this.billingInformation.billingResponsibleBusinessId =
                    jsonInput.billingInformation.billingResponsibleBusinessId;
                this.billingInformation.billingResponsibleCustomerNumber =
                    jsonInput.billingInformation.billingResponsibleCustomerNumber;
                this.billingInformation.emailInvoiceAddress = jsonInput.billingInformation.emailInvoiceAddress;
            } else {
                this.billingInformation = {};
            }

            this.deliveryInformationValid = jsonInput.deliveryInformationValid;
            this.deliveryInformation = {};
            if (jsonInput.deliveryInformation != null) {
                this.deliveryInformation.deliveryRecipient = jsonInput.deliveryInformation.deliveryRecipient;
                if (jsonInput.deliveryInformation.deliveryAddress != null) {
                    this.deliveryInformation.deliveryAddress = new Address(
                        jsonInput.deliveryInformation.deliveryAddress
                    );
                }
                this.deliveryInformation.deliveryCustomerNumber = jsonInput.deliveryInformation.deliveryCustomerNumber;
            }

            this.usesRemovedProducts = jsonInput.usesRemovedProducts;
            this.status = jsonInput.status;
            this.notes = [];
            if (jsonInput.notes && jsonInput.notes.length > 0) {
                for (let n of jsonInput.notes) {
                    this.notes.push(new Note(n));
                }
                this.notes = sortBy(this.notes, function (item) {
                    return -item.noteSaved.valueOf();
                });
            }
            this.serviceArea = jsonInput.serviceArea;

            //this.billingTerms = jsonInput.billingTerms;
            this.invoicingTerms = jsonInput.invoicingTerms ? new ParameterValue(jsonInput.invoicingTerms) : null;
            this.deliveryTerms = jsonInput.deliveryTerms ? new ParameterValue(jsonInput.deliveryTerms) : null;
            this.deliveryMethod = jsonInput.deliveryMethod ? new ParameterValue(jsonInput.deliveryMethod) : null;
            this.contractNumber = jsonInput.contractNumber ? new ParameterValue(jsonInput.contractNumber) : null;
            this.ourReference = jsonInput.ourReference ? new ParameterValue(jsonInput.ourReference) : null;
            this.packingInstructions = jsonInput.packingInstructions;
            this.reference = jsonInput.reference;
            this.vatId = jsonInput.vatId;

            this.partyRelations = [];
            if (jsonInput.partyRelations && jsonInput.partyRelations.length > 0) {
                for (const p of jsonInput.partyRelations) {
                    this.partyRelations.push(new PartyRelation(p));
                }
            }
            this.portfolio = new Portfolio(jsonInput.portfolio);
            this.emailInvoiceAddress =
                jsonInput.emailInvoiceAddress !== null && verifyEmailAddress(jsonInput.emailInvoiceAddress)
                    ? jsonInput.emailInvoiceAddress
                    : null;
            this.useCustomerEmailInvoiceAddress = jsonInput.useCustomerEmailInvoiceAddress;
            this.useBillingCustomerEmailInvoiceAddress = jsonInput.useBillingCustomerEmailInvoiceAddress;
            this.contract = new Contract(jsonInput.contract);
        } else {
            this.id = 0;
            this.storageId = null;
            this.name = '';
            this.businessId = '';
            this.customerNumber = null;
            this.contractNumber = '';
            this.address = null;
            this.locationNumber = 0;
            this.contactPersonName = '';
            this.contactPersonTel = '';
            this.additionalInfo = '';
            this.deliveryCustomer = null;
            this.deliveryAddress = null;
            this.customer = null;
            this.billingAddress = null;
            this.electronicInvoiceAddress = null;

            this.reminderMonths = [];

            this.useCustomerEInvoiceInfo = false;
            this.useCustomerBillingAddress = false;
            this.machines = [];
            this.orderValueWithoutVat = 0;
            this.latestOrders = [];

            this.billingCustomer = null;
            this.useBillingCustomerEInvoiceInfo = false;
            this.useBillingCustomerBillingAddress = false;

            this.billingInformation = {};
            this.usesRemovedProducts = false;
            this.status = CustomershipStatus.Active();
            this.billingTerms = '';
            this.deliveryTerms = '';
            this.deliveryMethod = '';
            this.notes = [];
            this.serviceArea = '';

            this.invoicingTerms = null;
            this.deliveryTerms = null;
            this.deliveryMethod = null;
            this.contractNumber = null;
            this.packingInstructions = '';
            this.partyRelations = [];
            this.emailInvoiceAddress = null;
            this.useCustomerEmailInvoiceAddress = false;
            this.useBillingCustomerEmailInvoiceAddress = false;
        }
    }

    allFilters() {
        return flatten(this.machines.map((m) => m.filters));
    }

    containsDraftProducts() {
        return !every(
            filter(flatten(this.machines.map((m) => m.filters)), (item) => {
                return item.product != null;
            }),
            ['product.status', FilterProduct.StatusInUse()]
        );
    }

    calcLocationValue(includeOnlyProductsWithProductionPrice = false) {
        let orderValueWithoutVat = 0.0;
        for (const m of this.machines) {
            for (const f of m.filters) {
                if (includeOnlyProductsWithProductionPrice && (!f.productionPrice() || f.productionPrice() === 0)) {
                    continue;
                }
                orderValueWithoutVat += f.getFilterTotalValue();
            }
        }
        return orderValueWithoutVat.toFixed(2);
    }

    marginEur() {
        const prodPrices = sumBy(this.allFilters(), (f) => f.productionPrice() * f.count);
        const total = this.calcLocationValue(true);
        if (!prodPrices || !total) return 0;
        return (total - prodPrices).toFixed(2);
    }

    marginPercentage() {
        const prodPrices = sumBy(this.allFilters(), (f) => f.productionPrice() * f.count);
        const total = this.calcLocationValue(true);
        if (!prodPrices || !total) return 0;
        return ((1 - prodPrices / total) * 100).toFixed(2);
    }

    allProductsHaveProdPrice = () => {
        return every(flatten(this.machines.map((m) => m.filters)), (item) => item.productionPrice() > 0);
    };

    replaceProduct = (oldProductId, newProduct, filterId, machineId, replaceType, contractPrice) => {
        let toChange = [];
        switch (replaceType) {
            case 'filter':
                toChange = this.allFilters().filter((f) => f.id === filterId);
                if (toChange.length !== 1) {
                    Logger.LogError(
                        `location.js; intent was to change only one filter, multiple found, should not happend. Location:${
                            this.id
                        }, Filter id: ${filterId}, Oldproduct:${oldProductId}, newproduct:${JSON.stringify(
                            newProduct
                        )}`,
                        ''
                    );
                    throw new Error('error');
                }
                if (
                    (toChange[0].product && toChange[0].product.id !== oldProductId) ||
                    (toChange[0].bundle && toChange[0].bundle.id !== oldProductId)
                ) {
                    Logger.LogError(
                        `location.js; intent was to change only one filter but the product id does not match. Location:${
                            this.id
                        }, Filter id: ${filterId}, Oldproduct:${oldProductId}, newproduct:${JSON.stringify(
                            newProduct
                        )}`,
                        ''
                    );
                    throw new Error('error');
                }
                break;
            case 'machine':
                toChange = this.machines
                    .filter((m) => m.id === machineId)[0]
                    .filters.filter(
                        (f) =>
                            (f.product && f.product.id === oldProductId) || (f.bundle && f.bundle.id === oldProductId)
                    );

                break;
            case 'location':
                toChange = this.allFilters().filter(
                    (f) => (f.product && f.product.id === oldProductId) || (f.bundle && f.bundle.id === oldProductId)
                );
                break;
            default:
                Logger.LogError(
                    `locationview.jsx; undefined replace type:${replaceType} for loc:${
                        this.id
                    }, machine: ${machineId}, filter:${filterId}. Oldproduct:${oldProductId}, newproduct:${JSON.stringify(
                        newProduct
                    )}`,
                    ''
                );
                throw new Error('error');
        }

        for (const f of toChange) {
            const discount = f.discount;
            const price = f.price;
            if (newProduct.productType() === 'fp') {
                f.bundle = null;
                f.product = newProduct;
            } else {
                f.product = null;
                f.bundle = newProduct;
            }
            if (contractPrice && (contractPrice.discount !== null || contractPrice.price !== null)) {
                f.discount = contractPrice.discount;
                f.price = contractPrice.price;
                f.contractPricesInUse = true;
            } else {
                f.discount = discount === null && price === null ? 0 : discount;
                f.price = price;
                f.contractPricesInUse = false;
            }
        }
    };

    static async NewObject() {
        const newLocation = new Location();
        try {
            const resps = await Promise.all([ObjectIdGenerator.newId(), ObjectIdGenerator.newCustomerNumber()]);
            newLocation.id = resps[0];
            newLocation.customerNumber = resps[1];
            return newLocation;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
}

export default Location;
