import React from 'react';
import CustomerAutoComplete from '../common/customerautocomplete';
import Location from '../domain/location';
import Customer from '../domain/customer';
import Address from '../domain/address';
import ElectronicInvoiceInfo from '../domain/electronicinvoiceinfo';
import LocationEditMachines from './locationeditmachines';
import Spinner from '../common/spinner';
import { DataRemoveStatus, SaveFailureReason } from '../modules/locationdata';
import store from '../store';
import { DialogActions, DialogContent, InputAdornment, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import * as customerActions from '../modules/customerdata';
import useTheme from '@material-ui/core/styles/useTheme';
import LocationDeliveryAddressResolver from '../modules/locationdeliveryaddressresolver';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Footer from '../main/footer';
import FooterPadding from '../main/footerpadding';
import { monthNumberToText } from '../common/common';
import AddressInput from '../common/addressinput';
import EInvoiceInput from '../common/einvoiceinput';
import CustomershipStatus from '../domain/customershipstatus';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ParameterSelector from '../common/parameterselector';
import { round } from 'mathjs';
import { formatCurrency } from '../common/common';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import PartyView from '../common/partyview';
import PartyRelation from '../domain/partyrelation';
import BisLookupContainer from '../common/bislookupcontainer';
import Alert from '@material-ui/lab/Alert';
import DuplicateItemsDialog, { TypeLocation } from '../common/duplicateitemsview';
import LocationMargin from './locationmargin';
import { ResolveErrorMessage } from '../common/errormessageresolver';
import LocationBillingInfoView from './locationBillingInfoView';
import LocationOrderValueSetDialog from './locationOrderValueSetDialog';
import { flatten, sumBy } from 'lodash';
import ContractPrice from '../domain/contractprice';
import Logger from '../common/logger';
import { toast } from 'react-toastify';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Switch, FormGroup, FormControlLabel } from '@material-ui/core';
import i18next from 'i18next';
import Add from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailInvoiceAddressInput from '../common/emailinvoiceaddressinput';

const styles = (theme) => ({
    root: {
        width: '100%',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    select: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '95%',
    },
    btnContainer: {
        marginTop: theme.spacing(1),
    },
    indent: {
        marginLeft: theme.spacing(1),
    },
    search: {
        cursor: 'pointer',
    },
    duplicateAlert: {
        cursor: 'pointer',
    },
    sidenote: {
        marginLeft: '10px',
        fontSize: '0.8em',
    },
});

function t(key, params) {
    return i18next.t(key, params) ? i18next.t(key, params) : key;
}

const expandSvgPath =
    'M18 8H8v10H6V8a2 2 0 0 1 2-2h10v2m-4-6H4a2 2 0 0 0-2 2v10h2V4h10V2m8 10v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2m-2 3h-3v-3h-2v3h-3v2h3v3h2v-3h3v-2z';
const collapseSvgPath =
    'M14 4H4v10H2V4a2 2 0 0 1 2-2h10v2m4 2H8a2 2 0 0 0-2 2v10h2V8h10V6m4 6v8a2 2 0 0 1-2 2h-8a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2m-2 3h-8v2h8v-2z';
class LocationEditView extends React.Component {
    constructor(props) {
        super(props);
        this.fieldEditTimer = null;
        this.duplicateCheckTimer = null;
        this.unmounting = false;
        this.state = {
            location: null,
            locationId: null,
            saving: false,
            saveDone: false,
            validationErrors: {},
            confirmDeleteModalOpen: false,
            itemToBeDeleted: '',
            addCustomerModalOpen: false,
            addDeliveryCustomerModalOpen: false,
            monthSelection: [],
            expandBilling: false,
            expandDelivery: false,
            expandAddress: false,
            expandRemainders: false,
            expandCustomer: false,
            expandContactDetails: false,
            billingInfoCalculatedFromLocation: null,
            expandMachines: false,
            hasUnsavedChanges: false,
            hasAgreedToDiscardChanges: false,
            confirmDiscardChangesModalOpen: false,
            savedLocation: null,
            expandMachineDetails: false,
            bisLookupOpen: false,
            bisLookupSearchTerm: null,
            bisLookupBusinessIdTerm: null,
            editActionId: null,
            duplicateViewOpen: false,
            orderValueSetDialogOpen: false,
            hasContractPriceProducts: true,
            contracts: {},
        };
        this.setStateCalled = false;
    }

    toggleBilling = () => {
        this.setState({ ...this.state, expandBilling: !this.state.expandBilling });
    };
    toggleDelivery = () => {
        this.setState({ ...this.state, expandDelivery: !this.state.expandDelivery });
    };
    toggleAddress = () => {
        this.setState({ ...this.state, expandAddress: !this.state.expandAddress });
    };
    toggleContactDetails = () => {
        this.setState({ ...this.state, expandContactDetails: !this.state.expandContactDetails });
    };
    toggleRemainders = () => {
        this.setState({ ...this.state, expandRemainders: !this.state.expandRemainders });
    };
    toggleCustomer = () => {
        this.setState({ ...this.state, expandCustomer: !this.state.expandCustomer });
    };
    toggleMachines = () => {
        let expandMachineDetailsNewState = this.state.expandMachines ? false : this.state.expandMachineDetails;
        this.setState({
            ...this.state,
            expandMachines: !this.state.expandMachines,
            expandMachineDetails: expandMachineDetailsNewState,
        });
    };
    expandMachineDetails = (event) => {
        this.setState({ ...this.state, expandMachineDetails: true });
        event.stopPropagation();
    };
    collapseMachineDetails = (event) => {
        this.setState({ ...this.state, expandMachineDetails: false });
        event.stopPropagation();
    };

    updateBillingInformation = (loc) => {
        let _this = this;

        let locationToTest = new Location(loc);
        this.sanitizeLocationJson(locationToTest);

        if (locationToTest.deliveryCustomer) locationToTest.deliveryCustomer.treeData = undefined;
        if (locationToTest.billingCustomer) locationToTest.billingCustomer.treeData = undefined;

        //strip unnecessary customer data
        if (locationToTest.customer != null) {
            locationToTest.customer.treeData = undefined;
            locationToTest.customer.customerLocations = null;
            locationToTest.customer.customerAsBillingCustomerLocations = null;
            locationToTest.customer.customerAsDeliveryCustomerLocations = null;
            locationToTest.customer.notes = null;
        }

        this.props.getBillingInformation(locationToTest).then((resp) => {
            _this.setState({ ..._this.state, billingInfoCalculatedFromLocation: resp.data });
        });
    };
    setState = (newState) => {
        this.setStateCalled = true;
        super.setState(newState);
    };
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setStateCalled = false;
        if (this.state.hasUnsavedChanges && this.state.hasAgreedToDiscardChanges) {
            this.onCancel();
            return;
        }
        if (this.props.locationRemoveStatus === DataRemoveStatus.Removing) {
            this.props.history.go(-2);
            return;
        } else if (this.props.locationRemoveStatus === DataRemoveStatus.RemoveSuccess) {
            toast.info(t('location.toast.locationRemoved'), { autoClose: 2000, hideProgressBar: true });
            return;
        } else if (this.props.locationRemoveStatus === DataRemoveStatus.RemoveFailed) {
            toast.error(t('location.toast.locationRemovalFailed'), { autoClose: 5000 });
            return;
        }

        if (this.state.location === null && this.props.location != null) {
            let _state = { ...this.state };
            _state.location = new Location(this.props.location);
            _state.locationId = this.props.location.id;
            this.updateBillingInformation(_state.location);
            this.setState(_state);
        }

        if (!this.setStateCalled) {
            if (!this.state.savedLocation) {
                this.setState({ ...this.state, savedLocation: this.getCompareString(this.state.location) });
            } else {
                const propsLocation = this.props.location;
                const prevLocation = prevProps.location;

                if (propsLocation && prevLocation && propsLocation.storageId !== prevLocation.storageId) {
                    // location update via backend (either via submit or coming via websocket)
                    this.setState({
                        ...this.state,
                        hasUnsavedChanges: false,
                        location: new Location(this.props.location),
                        savedLocation: this.getCompareString(new Location(this.props.location)),
                    });
                } else {
                    // some other update to state/props.
                    const currentJson = this.getCompareString(this.state.location);
                    if ((currentJson !== this.state.savedLocation) !== this.state.hasUnsavedChanges) {
                        this.setState({
                            ...this.state,
                            hasUnsavedChanges: !this.state.hasUnsavedChanges,
                        });
                    }
                }
            }
        }
    }

    getCompareString(location) {
        return JSON.stringify(this.prepareForCompare(location));
    }
    // remove nulls, convert numbers to strings, etc.
    prepareForCompare(o) {
        if (Array.isArray(o)) {
            let arr = [];
            for (let i in o) {
                arr[i] = this.prepareForCompare(o[i]);
            }
            return arr;
        } else if (typeof o == 'number') {
            return o.toFixed(2);
        } else if (typeof o == 'string') {
            if (o === '0') {
                return '0.00';
            }
            return o;
        } else if (typeof o != 'object') {
            return o;
        }
        let obj = { ...o };
        let removeKeys = [];
        let keys = Object.keys(obj);
        for (let k in keys) {
            let key = keys[k];
            let item = obj[key];
            if (item === null) {
                removeKeys.push(key);
                continue;
            } else {
                item = this.prepareForCompare(item);
            }
            obj[key] = item;
        }
        for (let i in removeKeys) {
            delete obj[removeKeys[i]];
        }
        return obj;
    }

    componentDidMount() {
        if (this.props.newLocation && this.state.location === null) {
            let _this = this;
            // new location
            Location.NewObject()
                .then(function (location) {
                    _this.updateBillingInformation(location);
                    _this.setState({
                        ..._this.state,
                        location: location,
                        locationId: 'new',
                    });
                })
                .catch(function (err) {
                    console.log(err);
                });
        } else if (this.state.location === null && this.props.location != null) {
            let _state = { ...this.state };
            _state.location = new Location(this.props.location);
            _state.locationId = this.props.location.id;
            this.updateBillingInformation(_state.location);
            this.triggerDuplicateCheck();
            this.setState(_state);
        }
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if (this.state.hasUnsavedChanges) {
            event.preventDefault();
            event.returnValue = t('general.confirmLeave');
        }
    };

    hasDuplicates = () => {
        return this.props.duplicates[this.state.editActionId] &&
        this.props.duplicates[this.state.editActionId].locationDuplicates &&
        this.props.duplicates[this.state.editActionId].locationDuplicates.duplicatesByName &&
        this.props.duplicates[this.state.editActionId].locationDuplicates.duplicatesByName.length > 0;
    }

    isValidLocation = (location) => {
        if (!location) return false;
        let hasErrors = !location.name ||
            location.name.length < 3 ||
            location.contractNumber === null ||
            this.hasDuplicates() ||
            !this.addressesOk();
        return !hasErrors;
    }

    componentWillUnmount() {
        if (this.state.hasUnsavedChanges && !this.state.hasAgreedToDiscardChanges && this.isValidLocation(this.state.location)) {
            let toBeSavedLocation = new Location(this.state.location);
            toast.info(t('location.toast.locationHasUnsavedChangesSaving'), {
                autoClose: 2000,
                hideProgressBar: true,
            });
            this.props
                .saveLocation(toBeSavedLocation)
                .then(() => {
                    toast.info(t('location.toast.automaticLocationSaveSuccess'), {
                        autoClose: 2000,
                        hideProgressBar: true,
                    });
                })
                .catch(() => {
                    toast.error(t('location.toast.automaticLocationSaveFailed'), { autoClose: 3000 });
                });
        }
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    cancelDiscard = () => {
        this.setState({
            ...this.state,
            confirmDiscardChangesModalOpen: false,
            hasAgreedToDiscardChanges: false,
        });
    };

    confirmDiscard = () => {
        this.setState({
            ...this.state,
            hasAgreedToDiscardChanges: true,
            confirmDiscardChangesModalOpen: false,
        });
    };

    onCancel = () => {
        if (this.state.saving) return; // do nothing

        if (this.state.hasUnsavedChanges && !this.state.hasAgreedToDiscardChanges) {
            this.setState({
                ...this.state,
                confirmDiscardChangesModalOpen: true,
            });
            return;
        }
        this.props.history.goBack();
    };

    onSubmit = (event) => {
        if (this.state.saving) return; // do nothing

        let newState = { ...this.state };
        newState.validationErrors = {};
        newState.saving = true;
        this.setState(newState);

        //validate
        if (this.state.location.name.trim().length === 0) {
            let newState = { ...this.state };
            newState.validationErrors['name'] = 'Tieto puuttuu';
            newState.saving = false;
            this.setState(newState);
            return;
        }
        let toBeSavedLocation = new Location(this.state.location);

        // Sanitize json output
        // TODO: fix these
        this.sanitizeLocationJson(toBeSavedLocation);

        toast.info('Tallennetaan..', { autoClose: 1000, hideProgressBar: true });

        let _this = this;
        this.props
            .saveLocation(toBeSavedLocation)
            .then(() => {
                toast.info(t('location.toast.locationSaved'), { autoClose: 1000, hideProgressBar: true });
                let newState = { ..._this.state };
                newState.saveDone = true;
                newState.saving = false;
                newState.hasUnsavedChanges = false;
                if (_this.state.locationId === 'new') {
                    newState.locationId = _this.state.location.id;
                    _this.props.history.replace('/locations/edit/' + _this.state.location.id);
                }
                newState.savedLocation = _this.getCompareString(newState.location);
                _this.setState(newState);
            })
            .catch(() => {
                let newState = { ..._this.state };
                newState.saveDone = true;
                newState.saving = false;
                if (_this.props.saveFailReason === SaveFailureReason.VersionConflict)
                    toast.error(t('toast.saveFailedConflict'), { autoClose: 5000 });
                else toast.error(t('toast.saveFailed'), { autoClose: 5000 });

                _this.setState(newState);
            });
    };

    sanitizeLocationJson = (toBeSavedLocation) => {
        toBeSavedLocation.customerNumber = parseInt(toBeSavedLocation.customerNumber);
        toBeSavedLocation.orderValueWithoutVat = parseFloat(toBeSavedLocation.orderValueWithoutVat);
        if (toBeSavedLocation.machines != null && toBeSavedLocation.machines.length > 0) {
            for (let m of toBeSavedLocation.machines) {
                m.airVolume = parseFloat(m.airVolume);
                if (m.filters != null && m.filters.length > 0) {
                    for (let f of m.filters) {
                        f.discount = f.discount !== null ? parseFloat(f.discount) : null;
                        f.price = f.price !== null ? parseFloat(f.price) : null;
                    }
                }
            }
        }
    };

    onRemove = (event) => {
        this.removeLocation();
    };

    removeLocationData = () => {
        let toBeSavedLocation = new Location(this.state.location);
        toast.info(t('toast.removing'), { autoClose: 1000, hideProgressBar: true });
        this.props.removeLocation(toBeSavedLocation);
    };

    updateLocationValue = () => {
        let orderValueWithoutVat = 0.0;
        if (this.state.location == null) return;
        for (let m of this.state.location.machines) {
            for (let f of m.filters) {
                orderValueWithoutVat += f.getFilterTotalValue();
            }
        }
        let newState = { ...this.state };
        newState.location.orderValueWithoutVat = orderValueWithoutVat.toFixed(2);
        this.setState(newState);
    };

    sortByViewOrder = (a, b) => {
        return a.viewOrder - b.viewOrder;
    };
    machineChanged = (machine) => {
        let newMachines = [];
        for (let m of this.state.location.machines) {
            if (m.id === machine.id) {
                newMachines.push(machine);
            } else {
                newMachines.push(m);
            }
        }
        newMachines.sort(this.sortByViewOrder);
        for (const m of newMachines) {
            m.filters.sort(this.sortByViewOrder);
        }
        let newState = { ...this.state };
        newState.location.machines = newMachines;
        this.setState(newState);
        this.updateLocationValue();
    };

    machineAdded = (machine) => {
        let newState = { ...this.state };
        newState.location.machines.push(machine);
        this.setState(newState);
        this.updateLocationValue();
    };

    getFilterDiscount = (product) => {
        for (let machine of this.state.location.machines) {
            for (let filter of machine.filters) {
                if (!product.bundle && filter.product && filter.product.id === product.id) {
                    return filter.discount;
                } else if (product.bundle && filter.bundle && filter.bundle.id === product.id) {
                    return filter.discount;
                }
            }
        }
        return null;
    };

    setFilterDiscount = (id, discount) => {
        let newState = { ...this.state };
        let prodId = null;
        for (let machine of newState.location.machines) {
            for (let filter of machine.filters) {
                if (filter.id === id) {
                    filter.discount = discount;
                    filter.price = null;
                    prodId = filter.product ? filter.product.id : filter.bundle.id;
                }
            }
        }
        if (prodId) {
            for (let machine of newState.location.machines) {
                for (let filter of machine.filters) {
                    if (
                        (filter.product && filter.product.id === prodId) ||
                        (filter.bundle && filter.bundle.id === prodId)
                    ) {
                        filter.discount = discount;
                        filter.price = null;
                    }
                }
            }
        }
        this.setState(newState);
        this.updateLocationValue();
    };

    getFilterPrice = (product) => {
        for (let machine of this.state.location.machines) {
            for (let filter of machine.filters) {
                if (!product.bundle && filter.product && filter.product.id === product.id) {
                    return filter.price;
                } else if (product.bundle && filter.bundle && filter.bundle.id === product.id) {
                    return filter.price;
                }
            }
        }
        return null;
    };

    setFilterPrice = (id, price) => {
        let newState = { ...this.state };
        let prodId = null;
        for (let machine of newState.location.machines) {
            for (let filter of machine.filters) {
                if (filter.id === id) {
                    filter.price = price;
                    filter.discount = null;
                    prodId = filter.product ? filter.product.id : filter.bundle.id;
                }
            }
        }
        if (prodId) {
            for (let machine of newState.location.machines) {
                for (let filter of machine.filters) {
                    if (
                        (filter.product && filter.product.id === prodId) ||
                        (filter.bundle && filter.bundle.id === prodId)
                    ) {
                        filter.discount = null;
                        filter.price = price;
                    }
                }
            }
        }
        this.setState(newState);
        this.updateLocationValue();
    };

    swapDiscountPrice = (id) => {
        let newState = { ...this.state };
        let newPrice = null;
        let newDiscount = null;
        let prodId = null;
        for (let machine of newState.location.machines) {
            for (let filter of machine.filters) {
                if (filter.id === id) {
                    prodId = filter.product ? filter.product.id : filter.bundle.id;
                    if (filter.discount === null) {
                        filter.discount = round(100 - (filter.price / filter.catalogValuePerPcs()) * 100, 4);
                        if (isNaN(filter.discount)) {
                            filter.discount = 0;
                        }
                        filter.price = null;
                    } else {
                        filter.price = filter.catalogValuePerPcs() * ((100 - filter.discount) / 100);
                        if (isNaN(filter.price)) {
                            filter.price = 0;
                        }
                        filter.discount = null;
                    }
                    newPrice = filter.price;
                    newDiscount = filter.discount;
                }
            }
        }
        if (prodId) {
            for (let machine of newState.location.machines) {
                for (let filter of machine.filters) {
                    if (
                        (filter.product && filter.product.id === prodId) ||
                        (filter.bundle && filter.bundle.id === prodId)
                    ) {
                        filter.discount = newDiscount;
                        filter.price = newPrice;
                    }
                }
            }
        }
        this.setState(newState);
        this.updateLocationValue();
    };

    machineRemoved = (machine) => {
        let newMachines = [];
        let ind = 1;
        for (let m of this.state.location.machines) {
            if (m.id === machine.id) {
            } else {
                m.viewOrder = ind;
                ind++;
                newMachines.push(m);
            }
        }
        let newState = { ...this.state };
        newState.location.machines = newMachines;
        this.setState(newState);
        this.updateLocationValue();
    };

    handleChange = (event) => {
        var newState = { ...this.state };
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let checkForDuplicate = false;

        switch (name) {
            case 'name':
                newState.location.name = value;
                checkForDuplicate = true;
                break;
            case 'customershipStatus':
                newState.location.status = value;
                break;
            case 'contactName':
                newState.location.contactPersonName = value;
                break;
            case 'contactTel':
                newState.location.contactPersonTel = value;
                break;
            case 'businessId':
                newState.location.businessId = value;
                checkForDuplicate = true;
                break;
            case 'serviceArea':
                newState.location.serviceArea = value;
                break;
            case 'packingInstructions':
                newState.location.packingInstructions = value;
                break;
            case 'reference':
                newState.location.reference = value;
                break;
            case 'vatId':
                newState.location.vatId = value;
                break;
            default:
                break;
        }

        this.setState(newState);
        if (checkForDuplicate) {
            this.triggerDuplicateCheck();
        }
        clearTimeout(this.fieldEditTimer);
        this.fieldEditTimer = setTimeout(() => {
            this.updateBillingInformation(this.state.location);
        }, 1000);
    };

    formHasErrors = () => {
        return Object.keys(this.state.validationErrors).length > 0;
    };

    removeElectronicInvoiceAddress = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.eInvoiceAddress'),
            confirmDeleteModalOpen: true,
        });
    };

    removeEmailInvoiceAddress = () => {
        const newState = { ...this.state };
        newState.location.emailInvoiceAddress = null;
        this.setState(newState);
        this.doTimedBillingInfoUpdate();
    };

    removeBillingAddress = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.invoicingAddress'),
            confirmDeleteModalOpen: true,
        });
    };

    removeCustomer = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.customer'),
            confirmDeleteModalOpen: true,
        });
    };

    removeLocation = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.location'),
            confirmDeleteModalOpen: true,
        });
    };

    confirmDelete = () => {
        let newState = { ...this.state, itemToBeDeleted: '', confirmDeleteModalOpen: false };
        switch (this.state.itemToBeDeleted) {
            case t('general.invoicingAddress'):
                newState.location.billingAddress = null;
                break;
            case t('general.customer'):
                newState.location.customer = null;
                newState.location.customerId = null;
                newState.location.useCustomerBillingAddress = false;
                newState.location.useCustomerEInvoiceInfo = false;
                break;
            case t('general.eInvoiceAddress'):
                newState.location.electronicInvoiceAddress = null;
                break;
            case t('general.deliveryCustomer'):
                newState.location.deliveryCustomer = null;
                newState.location.deliveryCustomerId = null;
                break;
            case t('general.deliveryAddress'):
                newState.location.deliveryAddress = null;
                break;
            case t('general.address'):
                newState.location.address = null;
                break;
            case t('general.location'):
                this.removeLocationData();
                break;
            case t('general.invoicingCustomer'):
                newState.location.billingCustomer = null;
                newState.location.billingCustomerId = null;
                newState.location.useBillingCustomerBillingAddress = false;
                newState.location.useBillingCustomerEInvoiceInfo = false;
                break;
            default:
                break;
        }
        this.setState(newState);
        this.updateBillingInformation(newState.location);
    };

    cancelDelete = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: '',
            confirmDeleteModalOpen: false,
        });
    };

    addCustomer = () => {
        this.setState({
            ...this.state,
            addCustomerModalOpen: true,
        });
    };
    cancelAddCustomer = () => {
        this.setState({
            ...this.state,
            addCustomerModalOpen: false,
        });
    };

    getCustomerFull = (customerId) => {
        let _this = this;
        if (this.props.customersById[customerId] != null) {
            return new Promise(function (resolve, reject) {
                resolve(_this.props.customersById[customerId]);
            });
        }
        return store.dispatch(customerActions.getCustomerFull(customerId));
    };

    customerSelected = (customerId) => {
        console.log(customerId);
        const _this = this;
        this.getCustomerFull(customerId)
            .then(function (cust) {
                console.log(cust);
                _this.setCustomer(new Customer(cust));
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    setCustomer = (customer) => {
        let newState = { ...this.state };
        newState.location.customer = customer;
        newState.location.customerId = customer.id;
        newState.addCustomerModalOpen = false;
        if (
            customer.electronicInvoiceAddress != null &&
            !this.state.location.electronicInvoiceAddress &&
            !this.state.location.useBillingCustomerEInvoiceInfo
        ) {
            newState.location.useCustomerEInvoiceInfo = true;
        }
        if (
            customer.billingAddress != null &&
            !this.state.location.billingAddress &&
            !this.state.location.useBillingCustomerBillingAddress
        ) {
            newState.location.useCustomerBillingAddress = true;
        }
        this.setState(newState);
        this.updateBillingInformation(newState.location);
    };

    addElectronicInvoiceAddress = () => {
        let newState = { ...this.state };
        let _this = this;
        ElectronicInvoiceInfo.NewObject()
            .then(function (info) {
                newState.location.electronicInvoiceAddress = info;
                newState.location.useBillingCustomerEInvoiceInfo = false;
                newState.location.useCustomerEInvoiceInfo = false;
                _this.setState(newState);
            })
            .catch(function (err) {
                console.log(err);
            });
    };
    addBillingAddress = () => {
        var newState = { ...this.state };
        var _this = this;
        Address.NewObject()
            .then(function (address) {
                newState.location.billingAddress = address;
                newState.location.useBillingCustomerBillingAddress = false;
                newState.location.useCustomerBillingAddress = false;
                _this.setState(newState);
            })
            .catch(function (err) {
                console.log(err);
            });
    };
    addEmailInvoiceAddress = () => {
        var newState = { ...this.state };
        newState.location.emailInvoiceAddress = '';
        newState.location.useBillingCustomerEmailInvoiceAddress = false;
        newState.location.useBillingCustomerEInvoiceInfo = false;
        newState.location.useCustomerEmailInvoiceAddress = false;
        newState.location.useCustomerEInvoiceInfo = false;
        this.setState(newState);
    };

    removeDeliveryCustomer = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.deliveryCustomer'),
            confirmDeleteModalOpen: true,
        });
    };
    removeDeliveryAddress = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.deliveryAddress'),
            confirmDeleteModalOpen: true,
        });
    };
    addDeliveryAddress = () => {
        var newState = { ...this.state };
        var _this = this;
        Address.NewObject()
            .then(function (address) {
                newState.location.deliveryAddress = address;
                _this.setState(newState);
            })
            .catch(function (err) {
                console.log(err);
            });
    };
    addDeliveryCustomer = () => {
        this.setState({
            ...this.state,
            addDeliveryCustomerModalOpen: true,
        });
    };
    deliveryCustomerSelected = (customerId) => {
        const _this = this;
        this.getCustomerFull(customerId)
            .then(function (customer) {
                _this.setDeliveryCustomer(new Customer(customer));
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    setDeliveryCustomer = (customer) => {
        let newState = { ...this.state };
        newState.location.deliveryCustomer = customer;
        newState.location.deliveryCustomerId = customer.id;
        newState.addDeliveryCustomerModalOpen = false;
        this.setState(newState);
        if (this.state.location.deliveryCustomer && !this.state.location.deliveryCustomer.deliveryAddress) {
            toast.error(t('location.toast.customerDoesNotHaveDeliveryAddressWarn'), { autoClose: 5000 });
        }
    };

    cancelAddDeliveryCustomer = () => {
        this.setState({
            ...this.state,
            addDeliveryCustomerModalOpen: false,
        });
    };

    removeAddress = () => {
        this.setState({
            ...this.state,
            confirmDeleteModalOpen: true,
            itemToBeDeleted: t('general.address'),
        });
    };
    addAddress = () => {
        var newState = { ...this.state };
        var _this = this;
        Address.NewObject()
            .then(function (address) {
                newState.location.address = address;
                _this.setState(newState);
            })
            .catch(function (err) {
                console.log(err);
            });
    };

    compareInt = (a, b) => {
        return a - b;
    };

    onMonthBtnClick = (selected) => {
        var newState = { ...this.state };
        const index = newState.location.reminderMonths.indexOf(selected);
        if (index < 0) {
            newState.location.reminderMonths.push(selected);
        } else {
            newState.location.reminderMonths.splice(index, 1);
        }
        newState.location.reminderMonths = newState.location.reminderMonths.sort(this.compareInt);
        this.setState(newState);
    };

    useCustomerEinvChange = () => {
        let newState = { ...this.state };
        if (!this.state.location.customer) return;
        if (this.state.location.customer && !this.state.location.customer.electronicInvoiceAddress) {
            toast.error(t('location.toast.customerDoesNotHaveEInvAddressWarn'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.electronicInvoiceAddress != null) {
            toast.warning(t('location.toast.removeEinvAddrFromLocationBeforeWarn'), {
                autoClose: 5000,
            });
            return;
        }
        if (this.state.location.emailInvoiceAddress != null && this.state.location.emailInvoiceAddress.length > 0) {
            toast.warning(t('location.toast.removeEmailInvAddressFromLocation'), { autoClose: 5000 });
            return;
        }

        newState.location.useCustomerEInvoiceInfo = !newState.location.useCustomerEInvoiceInfo;
        newState.location.useBillingCustomerEInvoiceInfo = false;
        newState.location.useCustomerEmailInvoiceAddress = false;
        newState.location.useBillingCustomerEmailInvoiceAddress = false;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };
    useCustomerBillingAddressChange = () => {
        let newState = { ...this.state };
        if (!this.state.location.customer) return;
        if (
            this.state.location.customer &&
            !this.state.location.customer.billingAddress &&
            !this.state.location.useCustomerBillingAddress
        ) {
            toast.error(t('location.toast.customerDoesNotHaveInvoicingAddressWarn'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.billingAddress != null) {
            toast.warning(t('location.toast.removeInvAddrFromLocationBeforeWarn'), { autoClose: 5000 });
            return;
        }
        newState.location.useCustomerBillingAddress = !newState.location.useCustomerBillingAddress;
        newState.location.useBillingCustomerBillingAddress = newState.location.useCustomerBillingAddress
            ? false
            : newState.location.useBillingCustomerBillingAddress;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };
    useCustomerEmailInvoiceAddressChange = () => {
        let newState = { ...this.state };
        if (!this.state.location.customer) return;
        if (
            this.state.location.customer &&
            (this.state.location.customer.emailInvoiceAddress == null ||
                this.state.location.customer.emailInvoiceAddress.length === 0) &&
            !this.state.location.useCustomerEmailInvoiceAddress
        ) {
            toast.error(t('location.toast.customerDoesNotHaveEmailInvoiceAddress'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.emailInvoiceAddress != null && this.state.location.emailInvoiceAddress.length > 0) {
            toast.warning(t('location.toast.removeEmailInvAddressFromLocation'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.electronicInvoiceAddress != null) {
            toast.warning(t('location.toast.removeEinvAddrFromLocationBeforeWarn'), { autoClose: 5000 });
            return;
        }
        newState.location.useCustomerEmailInvoiceAddress = !newState.location.useCustomerEmailInvoiceAddress;
        newState.location.useBillingCustomerEmailInvoiceAddress = false;
        newState.location.useBillingCustomerEInvoiceInfo = false;
        newState.location.useCustomerEInvoiceInfo = false;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };

    addBillingCustomer = () => {
        this.setState({ ...this.state, showAddBillingCustomerControl: true });
    };
    cancelAddBillingCustomer = () => {
        this.setState({ ...this.state, showAddBillingCustomerControl: false });
    };
    billingCustomerSelected = (customerId) => {
        const _this = this;
        this.getCustomerFull(customerId)
            .then(function (customer) {
                _this.setBillingCustomer(new Customer(customer));
            })
            .catch(function (err) {
                console.log(err);
            });
    };
    setBillingCustomer = (customer) => {
        let newState = { ...this.state };
        newState.location.billingCustomer = customer;
        newState.location.billingCustomerId = customer.id;
        newState.showAddBillingCustomerControl = false;
        this.setState(newState);
    };
    useBillingCustomerEinvChange = () => {
        if (this.state.location.billingCustomer && !this.state.location.billingCustomer.electronicInvoiceAddress) {
            toast.error(t('location.toast.customerDoesNotHaveEInvAddressWarn'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.electronicInvoiceAddress != null) {
            toast.warning(t('location.toast.removeEinvAddrFromLocationBeforeWarn'), {
                autoClose: 5000,
            });
            return;
        }
        if (this.state.location.emailInvoiceAddress != null && this.state.location.emailInvoiceAddress.length > 0) {
            toast.warning(t('location.toast.removeEmailInvAddressFromLocation'), { autoClose: 5000 });
            return;
        }
        let newState = { ...this.state };
        newState.location.useBillingCustomerEInvoiceInfo = !newState.location.useBillingCustomerEInvoiceInfo;
        newState.location.useCustomerEInvoiceInfo = false;
        newState.location.useBillingCustomerEmailInvoiceAddress = false;
        newState.location.useCustomerEmailInvoiceAddress = false;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };
    useBillingCustomerBillingAddressChange = () => {
        if (this.state.location.billingCustomer && !this.state.location.billingCustomer.billingAddress) {
            toast.error(t('location.toast.customerDoesNotHaveInvoicingAddressWarn'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.billingAddress != null) {
            toast.warning(t('location.toast.removeInvAddrFromLocationBeforeWarn'), {
                autoClose: 5000,
            });
            return;
        }
        let newState = { ...this.state };
        newState.location.useBillingCustomerBillingAddress = !newState.location.useBillingCustomerBillingAddress;
        newState.location.useCustomerBillingAddress = false;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };
    useBillingCustomerEmailInvoiceAddressChange = () => {
        if (
            this.state.location.billingCustomer &&
            (this.state.location.billingCustomer.emailInvoiceAddress == null ||
                this.state.location.billingCustomer.emailInvoiceAddress.length === 0)
        ) {
            toast.error(t('location.toast.customerDoesNotHaveEmailInvoiceAddress'), { autoClose: 5000 });
            return;
        }
        if (this.state.location.emailInvoiceAddress != null && this.state.location.emailInvoiceAddress.length > 0) {
            toast.warning(t('location.toast.removeEmailInvAddressFromLocation'), {
                autoClose: 5000,
            });
            return;
        }
        if (this.state.location.electronicInvoiceAddress != null) {
            toast.warning(t('location.toast.removeEinvAddrFromLocationBeforeWarn'), { autoClose: 5000 });
            return;
        }
        let newState = { ...this.state };
        newState.location.useBillingCustomerEmailInvoiceAddress =
            !newState.location.useBillingCustomerEmailInvoiceAddress;
        newState.location.useCustomerEInvoiceInfo = false;
        newState.location.useCustomerEmailInvoiceAddress = false;
        newState.location.useBillingCustomerEInvoiceInfo = false;
        this.doTimedBillingInfoUpdate();
        this.setState(newState);
    };
    removeBillingCustomer = () => {
        this.setState({
            ...this.state,
            itemToBeDeleted: t('general.invoicingCustomer'),
            confirmDeleteModalOpen: true,
        });
    };
    doTimedBillingInfoUpdate = () => {
        const _this = this;
        clearTimeout(this.fieldEditTimer);
        this.fieldEditTimer = setTimeout(() => {
            _this.updateBillingInformation(_this.state.location);
        }, 200);
    };
    billingAddressChanged = (address) => {
        let newState = { ...this.state };
        newState.location.billingAddress = address;
        this.setState(newState);
        this.doTimedBillingInfoUpdate();
    };
    einvoiceInfoChanged = (einv) => {
        let newState = { ...this.state };
        newState.location.electronicInvoiceAddress = einv;
        this.setState(newState);
        this.doTimedBillingInfoUpdate();
    };
    emailInvoiceAddressChanged = (event) => {
        let newState = { ...this.state };
        newState.location.emailInvoiceAddress = event.target.value;
        this.setState(newState);
        this.doTimedBillingInfoUpdate();
    };
    deliveryAddressChanged = (address) => {
        let newState = { ...this.state };
        newState.location.deliveryAddress = address;
        this.setState(newState);
    };
    locAddressChanged = (address) => {
        let newState = { ...this.state };
        newState.location.address = address;
        this.setState(newState);
    };
    isCustomerShipStatusOk = () => {
        return (
            this.state.location.status === CustomershipStatus.Potential() ||
            this.state.location.status === CustomershipStatus.Active()
        );
    };
    parameterSelectorChange = async (name, value) => {
        const nameOfProp = name[0].toLowerCase() + name.slice(1);
        const newLoc = new Location(this.state.location);

        if (nameOfProp === 'contractNumber') {
            if (value) {
                if (this.state.location.usesRemovedProducts) {
                    toast.warning(t('location.toast.contractPricesCannotBeTakenIntoUseExpiredProductsWarn'), {
                        autoClose: 5000,
                    });
                    return;
                }
                if (!this.props.contractPrices[value]) {
                    await this.props.getContractPrices(value);
                }
                this.switchToContractPricesDoNotRequirePricesToBeAvailable(value, newLoc);
                newLoc.contractNumber = this.props.parameters['ContractNumber'].find((item) => item.id === value);
                newLoc.contract = this.props.contracts[newLoc.contractNumber.id];
                if (newLoc.contract && newLoc.contract.deliveryTerms != null){
                    newLoc.deliveryTerms = newLoc.contract.deliveryTerms;
                }
                if (newLoc.contract && newLoc.contract.invoicingTerms != null){
                    newLoc.invoicingTerms = newLoc.contract.invoicingTerms;
                }
                this.setState({ ...this.state, location: newLoc });
            } else {
                this.switchOutOfContractPriceUsage(newLoc);
                newLoc.contractNumber = null;
                this.setState({ ...this.state, location: newLoc });
            }
        } else {
            newLoc[nameOfProp] = this.props.parameters[name].find((item) => item.id === value);
            this.setState({ ...this.state, location: newLoc });
        }
    };

    newContractPriceObjectsToCreate = (contractId) => {
        const newCps = [];
        const cprices = this.props.contractPrices[contractId];
        for (const m of this.state.location.machines) {
            if (m.filters && m.filters.length > 0) {
                for (const f of m.filters) {
                    const cp = cprices.find((p) => p.product.storageId === f.product.storageId);
                    if (cp && (cp.price !== null || cp.discount !== null)) {
                        //do nothing
                    } else if (!newCps.find((newCp) => newCp.product.storageId === f.product.storageId)) {
                        const cp = new ContractPrice({
                            contractId,
                            product: this.props.filterProducts.find((p) => p.storageId === f.product.storageId),
                            price: f.price,
                            discount: f.discount,
                        });
                        newCps.push(cp);
                    }
                }
            }
        }
        return newCps;
    };

    newPartyRelation = (party, relation) => {
        const newState = { ...this.state };
        newState.location.partyRelations.push(
            new PartyRelation({
                relatedParty: party,
                relation: relation,
            })
        );
        this.setState(newState);
    };

    removeParty = (id, relation) => {
        const newState = { ...this.state };
        const newrelations = [];
        for (let p of newState.location.partyRelations) {
            if (p.relatedParty.id !== id || p.relation !== relation) {
                newrelations.push(p);
            }
        }
        newState.location.partyRelations = newrelations;
        this.setState(newState);
    };

    handleBisLookup = () => {
        this.setState({ ...this.state, bisLookupOpen: true });
    };

    bisLookupResultCancel = () => {
        this.setState({ ...this.state, bisLookupOpen: false });
    };

    bisLookupResultSave = (name, businessId) => {
        var newState = { ...this.state };
        if (name !== null) newState.location.name = name;
        if (businessId !== null) newState.location.businessId = businessId;
        newState.bisLookupOpen = false;
        this.setState(newState);
        this.updateBillingInformation(this.state.location);
        this.triggerDuplicateCheck();
    };

    bisLookupByNameHandle = (term) => {
        this.setState({
            ...this.state,
            bisLookupOpen: true,
            bisLookupSearchTerm: term,
            bisLookupBusinessIdTerm: null,
        });
    };

    bisLookupByBusinessIdHandle = (businessId) => {
        this.setState({
            ...this.state,
            bisLookupOpen: true,
            bisLookupSearchTerm: null,
            bisLookupBusinessIdTerm: businessId,
        });
    };

    triggerDuplicateCheck = () => {
        let actionId = this.state.editActionId;
        if (actionId === null) {
            actionId = Math.floor(Math.random() * 1000000);
            this.setState({
                ...this.state,
                editActionId: actionId,
            });
        }
        clearTimeout(this.duplicateCheckTimer);
        if (this.state.location) {
            this.duplicateCheckTimer = setTimeout(() => {
                this.props.checkDuplicates(
                    this.state.editActionId,
                    this.state.location.id,
                    this.state.location.name,
                    this.state.location.businessId
                );
            }, 500);
        }
    };

    handleDuplicateViewClick = () => {
        this.setState({ ...this.state, duplicateViewOpen: !this.state.duplicateViewOpen });
    };

    anyPotentialDuplicates = (data) => {
        return (
            (data && data.duplicatesByName && data.duplicatesByName.length) ||
            (data && data.duplicatesByBusinessId && data.duplicatesByBusinessId.length)
        );
    };

    setNewOrderValueClick = () => {
        this.setState({ ...this.state, orderValueSetDialogOpen: !this.state.orderValueSetDialogOpen });
    };

    setNewOrderValueSave = (discount) => {
        let newState = { ...this.state };
        for (let machine of newState.location.machines) {
            for (let filter of machine.filters) {
                filter.discount = discount;
                filter.price = null;
            }
        }
        this.setState(newState);
        this.updateLocationValue();
        this.setNewOrderValueClick();
    };

    getLocationValueWithoutDiscount = () => {
        const allFilters = flatten(this.state.location.machines.map((m) => m.filters));
        return sumBy(allFilters, (f) => f.catalogValue());
    };

    // TODO: move this under Location domain object?
    switchOutOfContractPriceUsage = (location) => {
        for (const m of location.machines) {
            if (m.filters && m.filters.length > 0) {
                for (const f of m.filters) {
                    f.contractPricesInUse = false;
                }
            }
        }
    };

    // TODO: move this under Location domain object?
    //switchToContractPricesAssumeContractPricesAreInPlace = (contractId, location) => {
    switchToContractPricesDoNotRequirePricesToBeAvailable = (contractId, location) => {
        const cprices = this.props.contractPrices[contractId];
        for (const m of location.machines) {
            if (m.filters && m.filters.length > 0) {
                for (const f of m.filters) {
                    const cp = f.product
                        ? cprices.find((p) => p.product && p.product.storageId === f.product.storageId)
                        : cprices.find((p) => p.productBundle && p.productBundle.clusterId === f.bundle.clusterId);
                    if (cp && (cp.price !== null || cp.discount !== null)) {
                        f.price = cp.price;
                        f.discount = cp.discount;
                        f.contractPricesInUse = true;
                    } else {
                        f.contractPricesInUse = false;
                    }
                }
            }
        }
    };

    addressesOk = () => {
        if (this.state.location.billingAddress && !this.state.location.billingAddress.isValid()) {
            return false;
        }
        if (this.state.location.deliveryAddress && !this.state.location.deliveryAddress.isValid()) {
            return false;
        }
        return true;
    };

    replaceProduct = async (oldProductId, newProductId, filterId, machineId, replaceType) => {
        if (
            this.state.location.contractId &&
            !this.props.contractPrices &&
            !this.props.contractPrices[this.state.location.contractId]
        ) {
            await this.props.getContractPrices(this.state.location.contractId);
        }

        if (newProductId && oldProductId) {
            const newProd = this.props.filterProducts.find((p) => p.id === newProductId);
            const newBundle = this.props.productBundles.find((b) => b.id === newProductId);
            if (!newProd && !newBundle) {
                Logger.LogError(`locationview.jsx; cannot find new product, id:${newProductId}`, '');
                throw new Error('error');
            }
            const newLoc = new Location(this.state.location);
            let contractPrice = null;

            if (
                newLoc.contractId &&
                this.props.contractPrices[newLoc.contractId] &&
                this.props.contractPrices[newLoc.contractId].length > 0
            ) {
                if (newProd) {
                    contractPrice = this.props.contractPrices[newLoc.contractId].find(
                        (p) => p.product.storageId === newProd.storageId
                    );
                } else {
                    contractPrice = this.props.contractPrices[newLoc.contractId].find(
                        (p) => p.productBundle.clusterId === newBundle.clusterId
                    );
                }
            }

            newLoc.replaceProduct(
                oldProductId,
                newProd ? newProd : newBundle,
                filterId,
                machineId,
                replaceType,
                contractPrice
            );
            this.setState({ ...this.state, location: newLoc });
        }
    };

    getform = () => {
        const deliveryInfo = LocationDeliveryAddressResolver.getDeliveryAddress(this.state.location);
        const deliveryAndBillingCustomers = this.props.customers ? this.props.customers : [];
        const theme = useTheme();
        const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
        const location = this.state.location;
        const parameters = this.props.parameters;
        const classes = this.props.classes;
        const customershipPotential = CustomershipStatus.Potential();
        const customershipActive = CustomershipStatus.Active();
        let contract = null;
        if (location && location.contract && location.contract.contractNumber && this.props.contracts){
            contract = this.props.contracts[location.contract.contractNumber.id];
        }

        const hasDuplicates = this.hasDuplicates();

        const hasDuplicateWarnings =
            this.props.duplicates[this.state.editActionId] &&
            (this.anyPotentialDuplicates(this.props.duplicates[this.state.editActionId].customerDuplicates) ||
                this.anyPotentialDuplicates(this.props.duplicates[this.state.editActionId].locationDuplicates));

        if (this.state.location === null) {
            return <Spinner padding="20px" title={t('general.loading')} />;
        }
        var reminderText = '';
        if (this.state.location.reminderMonths != null && this.state.location.reminderMonths.length > 0) {
            for (let val of this.state.location.reminderMonths) {
                reminderText += ' ' + monthNumberToText(val);
            }
        }
        return (
            <Grid container spacing={1}>
                {hasDuplicates ? (
                    <Grid item xs={12}>
                        <Alert
                            severity="error"
                            onClick={this.handleDuplicateViewClick}
                            className={classes.duplicateAlert}>
                            {t('location.locationCouldNotBeSavedDuplicateWarn')}
                        </Alert>
                    </Grid>
                ) : hasDuplicateWarnings ? (
                    <Grid item xs={12}>
                        <Alert
                            severity="warning"
                            onClick={this.handleDuplicateViewClick}
                            className={classes.duplicateAlert}>
                            {t('location.potentialDuplicateWarn')}
                        </Alert>
                    </Grid>
                ) : null}
                {this.state.location.containsDraftProducts() && (
                    <Grid item xs={12}>
                        <Alert severity="warning">{t('location.draftProductsInUseCannotOrderWarn')}</Alert>
                    </Grid>
                )}
                <Grid item xs={12} md={6}>
                    {t('general.customerNumber')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {location.customerNumber}
                </Grid>
                {!this.props.showcaseMode && (
                    <React.Fragment>
                        <Grid item xs={12} md={6}>
                            {t('location.locationValue')}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <strong>{formatCurrency(this.state.location.orderValueWithoutVat)}&nbsp;(alv 0%)</strong>
                            &nbsp;
                            <Tooltip
                                title={
                                    this.state.location.contractNumber !== null &&
                                    this.state.location.contractNumber.id !== 1
                                        ? t('location.contractPricesInUseCannotSetLocationValue')
                                        : t('location.setLocationValue')
                                }>
                                <span>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        color="primary"
                                        name="btn-set-new-order-value"
                                        onClick={this.setNewOrderValueClick}
                                        disabled={
                                            this.state.location.contractNumber !== null &&
                                            this.state.location.contractNumber.id !== 1
                                        }>
                                        {t('location.buttons.setNewLocationValue')}
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {t('general.margin')}
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <LocationMargin location={this.state.location} />
                        </Grid>
                    </React.Fragment>
                )}
                <Grid item xs={12}>
                    <hr style={{ marginTop: '0' }} />
                </Grid>

                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        error={location.name == null || location.name.length === 0}
                        id="name"
                        name="name"
                        label={t('location.locationName')}
                        value={location.name ? location.name : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => this.bisLookupByNameHandle(location.name ? location.name : '')}
                                        className={classes.search}
                                        name="bis-search-by-name">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.select}>
                        <InputLabel id="customership-status-label">{t('customer.customerShipStatus')}</InputLabel>
                        <Select
                            required
                            error={!this.isCustomerShipStatusOk()}
                            labelId="customership-status-label"
                            id="customershipStatus"
                            name="customershipStatus"
                            value={location.status}
                            onChange={this.handleChange}>
                            <MenuItem value={customershipPotential}>{t('customer.statusPotential')}</MenuItem>
                            <MenuItem value={customershipActive}>{t('customer.statusActive')}</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="businessId"
                        name="businessId"
                        label={t('general.businessId')}
                        value={location.businessId ? location.businessId : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            this.bisLookupByBusinessIdHandle(
                                                location.businessId ? location.businessId : ''
                                            )
                                        }
                                        className={classes.search}
                                        name="bis-search-by-business-id">
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="vatId"
                        name="vatId"
                        label={t('general.vatId')}
                        value={location.vatId ? location.vatId : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'ContractNumber'}
                        selected={location.contractNumber}
                        handleChange={this.parameterSelectorChange}
                        required={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {(!contract || contract.invoicingTerms == null) && (
                        <ParameterSelector
                            values={parameters}
                            category={'InvoicingTerms'}
                            selected={location.invoicingTerms}
                            handleChange={this.parameterSelectorChange}
                        />
                    )}
                    {(contract && contract.invoicingTerms != null) && (
                        <ParameterSelector
                            disabled={true}
                            values={parameters}
                            category={'InvoicingTerms'}
                            selected={contract.invoicingTerms}
                            handleChange={this.parameterSelectorChange}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    {(!contract || contract.deliveryTerms == null) && (
                        <ParameterSelector
                            values={parameters}
                            category={'DeliveryTerms'}
                            selected={location.deliveryTerms}
                            handleChange={this.parameterSelectorChange}
                        />
                    )}
                    {(contract && contract.deliveryTerms != null) && (
                        <ParameterSelector
                            disabled={true}
                            values={parameters}
                            category={'DeliveryTerms'}
                            selected={contract.deliveryTerms}
                            handleChange={this.parameterSelectorChange}
                        />
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'DeliveryMethod'}
                        selected={location.deliveryMethod}
                        handleChange={this.parameterSelectorChange}
                    />
                    {location.customer && location.customer.deliveryMethod && (
                        <em className={classes.sidenote}>
                            {t('general.customer')}: {location.customer.deliveryMethod.value}
                        </em>
                    )}
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="serviceArea"
                        name="serviceArea"
                        label={t('general.serviceArea')}
                        value={location.serviceArea ? location.serviceArea : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="packingInstructions"
                        name="packingInstructions"
                        label={t('general.packingInstructions')}
                        value={location.packingInstructions ? location.packingInstructions : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="reference"
                        name="reference"
                        label={t('general.yourReference')}
                        value={location.reference ? location.reference : ''}
                        className={classes.textField}
                        margin="normal"
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ParameterSelector
                        values={parameters}
                        category={'OurReference'}
                        selected={location.ourReference}
                        handleChange={this.parameterSelectorChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr style={{ marginTop: '0' }} />
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={this.expandCustomer}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleCustomer}>
                            <Grid container>
                                <Grid item xs={6}>
                                    <h4>{t('general.customer')}</h4>
                                </Grid>
                                <Grid item xs={6}>
                                    {this.state.location.customer != null && (
                                        <em>{this.state.location.customer.name}</em>
                                    )}
                                    {this.state.location.customer == null && <em>{t('general.noCustomerSelected')}</em>}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {this.state.addCustomerModalOpen && (
                                    <React.Fragment>
                                        <Grid item xs={12} md={6}>
                                            <CustomerAutoComplete
                                                customers={this.props.customers}
                                                selected={this.customerSelected}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                id="btn-cancel-add-customer"
                                                variant="contained"
                                                color="secondary"
                                                name="btn-cancel-add-customer"
                                                onClick={this.cancelAddCustomer}>
                                                {t('buttons.cancel')}
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {this.state.location.customer && (
                                    <Grid item xs={12}>
                                        {t('general.customer')}: {this.state.location.customer.name}&nbsp;
                                        <IconButton size="small" color="secondary" onClick={this.removeCustomer}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                                {!this.state.location.customer && !this.state.addCustomerModalOpen && (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        name="btn-add-customer"
                                        startIcon={<Add />}
                                        onClick={this.addCustomer}>
                                        {t('buttons.add')}
                                    </Button>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={this.expandBilling}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleBilling}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <h4>{t('general.invoicingInfo')}</h4>
                                </Grid>
                                <Grid item container>
                                    {this.state.billingInfoCalculatedFromLocation != null && (
                                        <Grid item xs={12} md={4}>
                                            {this.state.billingInfoCalculatedFromLocation.billingInformationValid && (
                                                <React.Fragment>
                                                    {
                                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                                            .billingResponsible
                                                    }
                                                    ,&nbsp;
                                                    {
                                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                                            .billingAddress.streetAddress
                                                    }
                                                    ,&nbsp;
                                                    {
                                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                                            .billingAddress.zipCode
                                                    }
                                                    &nbsp;
                                                    {
                                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                                            .billingAddress.city
                                                    }
                                                </React.Fragment>
                                            )}
                                            {!this.state.billingInfoCalculatedFromLocation.billingInformationValid && (
                                                <em>
                                                    <i
                                                        className="fas fa-exclamation-triangle"
                                                        style={{ color: 'orange' }}></i>
                                                    &nbsp; {t('general.error')}!&nbsp;
                                                    {this.state.billingInfoCalculatedFromLocation.reason && (
                                                        <span>
                                                            {ResolveErrorMessage(
                                                                this.state.billingInfoCalculatedFromLocation.reason
                                                            )}
                                                        </span>
                                                    )}
                                                </em>
                                            )}
                                        </Grid>
                                    )}
                                    {this.state.billingInfoCalculatedFromLocation != null &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformationValid &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                            .electronicInvoiceAddress && (
                                            <Grid item xs={12} md={4}>
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .electronicInvoiceAddress.ovt
                                                }
                                                ,&nbsp;
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .electronicInvoiceAddress.operatorBrokerId
                                                }
                                                ,&nbsp;
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .electronicInvoiceAddress.operator
                                                }
                                            </Grid>
                                        )}
                                    {this.state.billingInfoCalculatedFromLocation != null &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformationValid &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                            .emailInvoiceAddress != null &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformation
                                            .emailInvoiceAddress.length > 0 && (
                                            <Grid item xs={12} md={4}>
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .emailInvoiceAddress
                                                }
                                            </Grid>
                                        )}
                                    {this.state.billingInfoCalculatedFromLocation != null &&
                                        this.state.billingInfoCalculatedFromLocation.billingInformationValid && (
                                            <Grid item xs={12} md={4}>
                                                {t('general.customerNumber')}:&nbsp;
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .billingResponsibleCustomerNumber
                                                }
                                                <br />
                                                {t('general.businessId')}:&nbsp;
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .billingResponsibleBusinessId
                                                }
                                                <br />
                                                {t('parameterCategories.ContractNumber')}:&nbsp;
                                                {
                                                    this.state.billingInfoCalculatedFromLocation.billingInformation
                                                        .billingResponsibleContractNumber
                                                }
                                            </Grid>
                                        )}
                                    <Grid item xs={12}>
                                        {this.state.billingInfoCalculatedFromLocation != null &&
                                            this.state.billingInfoCalculatedFromLocation.billingInformationValid && (
                                                <LocationBillingInfoView location={this.state.location} />
                                            )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {this.state.location.customer && (
                                    <Grid item container>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useCustomerBillingAddressChange}
                                                            checked={this.state.location.useCustomerBillingAddress}
                                                        />
                                                    }
                                                    label={t('location.useCustomerInvoiceAddress')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useCustomerEinvChange}
                                                            checked={this.state.location.useCustomerEInvoiceInfo}
                                                        />
                                                    }
                                                    label={t('location.useCustomerEInvoiceInfo')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useCustomerEmailInvoiceAddressChange}
                                                            checked={this.state.location.useCustomerEmailInvoiceAddress}
                                                        />
                                                    }
                                                    label={t('location.useCustomerEmailInvoiceAddress')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                )}
                                {this.state.location.billingCustomer && (
                                    <React.Fragment>
                                        <Grid item xs={12}>
                                            {t('general.invoicingCustomer')}: {this.state.location.billingCustomer.name}
                                            &nbsp;
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                onClick={this.removeBillingCustomer}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useBillingCustomerBillingAddressChange}
                                                            checked={
                                                                this.state.location.useBillingCustomerBillingAddress
                                                            }
                                                        />
                                                    }
                                                    label={t('location.useInvoicingCustomerInvoiceAddress')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useBillingCustomerEinvChange}
                                                            checked={this.state.location.useBillingCustomerEInvoiceInfo}
                                                        />
                                                    }
                                                    label={t('location.useInvoicingCustomerEInvoiceAddress')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={this.useBillingCustomerEmailInvoiceAddressChange}
                                                            checked={
                                                                this.state.location
                                                                    .useBillingCustomerEmailInvoiceAddress
                                                            }
                                                        />
                                                    }
                                                    label={t('location.useInvoicingCustomerEmailInvoiceAddress')}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {this.state.showAddBillingCustomerControl && (
                                    <React.Fragment>
                                        <Grid item xs={12} md={6}>
                                            <CustomerAutoComplete
                                                customers={deliveryAndBillingCustomers}
                                                selected={this.billingCustomerSelected}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                id="btn-cancel-add-billing-customer"
                                                name="btn-cancel-add-billing-customer"
                                                onClick={this.cancelAddBillingCustomer}>
                                                {t('buttons.cancel')}
                                            </Button>
                                        </Grid>
                                    </React.Fragment>
                                )}
                                {this.state.location.billingAddress && (
                                    <Grid item xs={12}>
                                        <strong>{t('general.invoicingAddress')}</strong>

                                        <IconButton size="small" color="secondary" onClick={this.removeBillingAddress}>
                                            <DeleteIcon />
                                        </IconButton>
                                        <AddressInput
                                            address={this.state.location.billingAddress}
                                            addressChanged={this.billingAddressChanged}
                                            idBase={'locBillingAddr-'}
                                        />
                                    </Grid>
                                )}
                                {this.state.location.electronicInvoiceAddress && (
                                    <Grid item xs={12}>
                                        <strong>{t('general.eInvoiceAddress')}&nbsp;</strong>

                                        <IconButton
                                            size="small"
                                            color="secondary"
                                            onClick={this.removeElectronicInvoiceAddress}>
                                            <DeleteIcon />
                                        </IconButton>

                                        <EInvoiceInput
                                            einvoiceinfo={this.state.location.electronicInvoiceAddress}
                                            einvoiceinfoChanged={this.einvoiceInfoChanged}
                                            idBase={'locEinv-'}
                                        />
                                    </Grid>
                                )}
                                {this.state.location.emailInvoiceAddress !== null && (
                                    <Grid item xs={12}>
                                        <strong>{t('invoice.emailInvoiceAddress')}&nbsp;</strong>

                                        <IconButton
                                            size="small"
                                            color="secondary"
                                            onClick={this.removeEmailInvoiceAddress}>
                                            <DeleteIcon />
                                        </IconButton>

                                        <EmailInvoiceAddressInput
                                            email={this.state.location.emailInvoiceAddress}
                                            onChange={this.emailInvoiceAddressChanged}
                                        />
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        {!this.state.location.billingCustomer && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    id="btn-add-billing-customer"
                                                    name="btn-add-billing-customer"
                                                    startIcon={<Add />}
                                                    onClick={this.addBillingCustomer}>
                                                    {t('general.invoicingCustomer')}
                                                </Button>
                                            </Grid>
                                        )}
                                        {!this.state.location.electronicInvoiceAddress && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    id="btn-add-einv-address"
                                                    name="btn-add-einv-address"
                                                    startIcon={<Add />}
                                                    onClick={this.addElectronicInvoiceAddress}>
                                                    {t('general.eInvoiceAddress')}
                                                </Button>
                                            </Grid>
                                        )}
                                        {!this.state.location.billingAddress && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    id="btn-add-billing-address"
                                                    name="btn-add-billing-address"
                                                    startIcon={<Add />}
                                                    onClick={this.addBillingAddress}>
                                                    {t('general.invoicingAddress')}
                                                </Button>
                                            </Grid>
                                        )}
                                        {this.state.location.emailInvoiceAddress === null && (
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    id="btn-add-email-invoice-address"
                                                    name="btn-add-email-invoice-address"
                                                    startIcon={<Add />}
                                                    onClick={this.addEmailInvoiceAddress}>
                                                    {t('invoice.emailInvoiceAddress')}
                                                </Button>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={this.state.expandDelivery}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleDelivery}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h4>{t('general.deliveryInfo')}</h4>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {deliveryInfo !== '' && <span>{deliveryInfo}</span>}
                                    {deliveryInfo === '' && (
                                        <span>
                                            <em>{t('general.deliveryInfoIncomplete')}</em>&nbsp;
                                            <i className="fas fa-exclamation-triangle" style={{ color: 'orange' }}></i>
                                        </span>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    {this.state.addDeliveryCustomerModalOpen && (
                                        <div>
                                            <CustomerAutoComplete
                                                customers={deliveryAndBillingCustomers}
                                                selected={this.deliveryCustomerSelected}
                                            />

                                            <Button
                                                id="btn-cancel-add-deliverycustomer"
                                                variant="contained"
                                                color="secondary"
                                                name="btn-cancel-add-deliverycustomer"
                                                onClick={this.cancelAddDeliveryCustomer}>
                                                {t('buttons.cancel')}
                                            </Button>
                                        </div>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    {this.state.location.deliveryCustomer && (
                                        <div>
                                            {t('general.deliveryCustomer')}: {this.state.location.deliveryCustomer.name}
                                            &nbsp;
                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                onClick={this.removeDeliveryCustomer}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {!this.state.location.deliveryCustomer && this.state.location.deliveryAddress && (
                                        <div>
                                            <strong>{t('general.deliveryAddress')}</strong>

                                            <IconButton
                                                size="small"
                                                color="secondary"
                                                name="remove-delivery-address"
                                                onClick={this.removeDeliveryAddress}>
                                                <DeleteIcon />
                                            </IconButton>

                                            <AddressInput
                                                address={location.deliveryAddress}
                                                addressChanged={this.deliveryAddressChanged}
                                                idBase={'locDeliveryAddr-'}
                                            />
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    {!this.state.location.deliveryCustomer && !this.state.location.deliveryAddress && (
                                        <div>
                                            <Button
                                                name="btn-add-delivery-customer"
                                                variant="contained"
                                                color="primary"
                                                startIcon={<Add />}
                                                onClick={this.addDeliveryCustomer}>
                                                {t('general.deliveryCustomer')}
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<Add />}
                                                onClick={this.addDeliveryAddress}>
                                                {t('general.deliveryAddress')}
                                            </Button>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={this.state.expandContactDetails}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleContactDetails}>
                            <Grid container>
                                <Grid item>
                                    <h4>{t('general.contactInformation')}</h4>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PartyView
                                        showControls={true}
                                        parties={this.state.location.partyRelations}
                                        saveNewPartyRelation={this.newPartyRelation}
                                        removeParty={this.removeParty}
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={t('general.contactPersonOrOrderer')}
                                        onChange={this.handleChange}
                                        name="contactName"
                                        value={
                                            this.state.location.contactPersonName
                                                ? this.state.location.contactPersonName
                                                : ''
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} sm={6}>
                                    <TextField
                                        label={t('general.phone')}
                                        onChange={this.handleChange}
                                        name="contactTel"
                                        value={
                                            this.state.location.contactPersonTel
                                                ? this.state.location.contactPersonTel
                                                : ''
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleAddress}>
                            <Grid container>
                                <Grid item>
                                    <h4>{t('general.address')}</h4>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    {this.state.location.address && (
                                        <AddressInput
                                            address={this.state.location.address}
                                            addressChanged={this.locAddressChanged}
                                            idBase={'locAddr'}
                                        />
                                    )}
                                    {!this.state.location.address && (
                                        <div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={this.addAddress}
                                                startIcon={<Add />}>
                                                {t('general.address')}
                                            </Button>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>

                <Grid item xs={12}>
                    <Accordion expanded={this.state.expandMachines}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            onClick={this.toggleMachines}
                            name="expand-machines">
                            <h4>
                                {t('location.machines')}
                                {this.state.expandMachines && (
                                    <React.Fragment>
                                        <IconButton
                                            name="expand-all-machines"
                                            aria-label="expand"
                                            color={'secondary'}
                                            onClick={(event) => this.expandMachineDetails(event)}>
                                            <SvgIcon>
                                                <path d={expandSvgPath} />
                                            </SvgIcon>
                                        </IconButton>
                                        <IconButton
                                            aria-label="collapse"
                                            color={'secondary'}
                                            onClick={(event) => this.collapseMachineDetails(event)}>
                                            <SvgIcon>
                                                <path d={collapseSvgPath} />
                                            </SvgIcon>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                            </h4>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    {this.state.expandMachines && (
                                        <LocationEditMachines
                                            machines={this.state.location.machines}
                                            filterProducts={this.props.filterProducts}
                                            productBundles={this.props.productBundles}
                                            machineChanged={this.machineChanged}
                                            machineAdded={this.machineAdded}
                                            machineRemoved={this.machineRemoved}
                                            getFilterDiscount={this.getFilterDiscount}
                                            setFilterDiscount={this.setFilterDiscount}
                                            swapDiscountPrice={this.swapDiscountPrice}
                                            setFilterPrice={this.setFilterPrice}
                                            getFilterPrice={this.getFilterPrice}
                                            expandDetails={this.state.expandMachineDetails}
                                            contractPrices={this.props.contractPrices}
                                            contractId={
                                                this.state.location.contractNumber &&
                                                this.state.location.contractNumber.id !== 1
                                                    ? this.state.location.contractNumber.id
                                                    : null
                                            }
                                            replaceProduct={this.replaceProduct}
                                            showcaseMode={this.props.showcaseMode}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    <Accordion expanded={this.state.expandRemainders}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={this.toggleRemainders}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    <h4>{t('location.reminders')}</h4>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {this.state.location.reminderMonths &&
                                        this.state.location.reminderMonths.length > 0 && <em>{reminderText}</em>}
                                    {(!this.state.location.reminderMonths ||
                                        this.state.location.reminderMonths.length === 0) && (
                                        <em>{t('location.notSet')}</em>
                                    )}
                                </Grid>
                            </Grid>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12}>
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month, ind) => (
                                        <button
                                            key={month}
                                            className={
                                                'btn btn-outline-primary ' +
                                                (this.state.location.reminderMonths &&
                                                this.state.location.reminderMonths.includes(ind + 1)
                                                    ? 'active'
                                                    : '')
                                            }
                                            onClick={() => this.onMonthBtnClick(ind + 1)}>
                                            {monthNumberToText(month)}
                                        </button>
                                    ))}
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Grid item xs={12}>
                    {this.formHasErrors() && <Alert severity="error">{t('location.errorsInForm')}</Alert>}
                </Grid>
                <Footer>
                    <Grid item>
                        {this.state.locationId !== 'new' && (
                            <Button
                                variant="contained"
                                name="poista"
                                color="secondary"
                                onClick={this.onRemove}
                                startIcon={<DeleteIcon />}
                                disabled={this.state.saving}>
                                {t('buttons.remove')}
                            </Button>
                        )}
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="tallenna"
                            onClick={this.onSubmit}
                            disabled={
                                this.state.saving ||
                                this.isValidLocation(this.state.location) === false
                            }>
                            {this.state.saving && <i className="fas fa-spinner fa-spin"></i>}
                            {!this.state.saving && <i className="fas fa-save"></i>}
                            &nbsp;{t('buttons.save')}
                            {!this.state.saving && this.state.locationId !== 'new' && this.state.hasUnsavedChanges && (
                                <div className="top-right-attention" style={{ color: 'Tomato' }}>
                                    <i className="fas fa-exclamation-circle top-right-attention"></i>
                                </div>
                            )}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            name="sulje"
                            onClick={this.onCancel}
                            disabled={this.state.saving}>
                            <i className="fas fa-window-close"></i>&nbsp;{t('buttons.close')}
                        </Button>
                    </Grid>
                </Footer>
                {this.props.saveLocationFail && (
                    <div style={{ padding: '20px' }}>
                        <Alert severity="error">{t('general.saveDataFailed')}</Alert>
                    </div>
                )}
                <FooterPadding />
                <Dialog
                    onClose={this.cancelDelete}
                    aria-labelledby="simple-dialog-title"
                    open={this.state.confirmDeleteModalOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}>
                    <DialogTitle>{t('general.confirmRemoveTitle')}</DialogTitle>
                    <DialogContent dividers>
                        <br />
                        <span>
                            {t('general.confirmRemove')} <strong>{this.state.itemToBeDeleted}</strong>?
                        </span>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            name="dialog-btn-locationedit-remove"
                            variant="contained"
                            color="primary"
                            onClick={this.confirmDelete}>
                            {t('buttons.remove')}
                        </Button>
                        <Button
                            name="dialog-btn-locationedit-cancel-remove"
                            variant="contained"
                            onClick={this.cancelDelete}>
                            {t('buttons.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                {this.state.confirmDiscardChangesModalOpen && (
                    <input type="hidden" name="discard_dialog_is_visible" value="discard_dialog_is_visible" />
                )}
                <Dialog
                    onClose={this.cancelDiscard}
                    aria-labelledby="simple-dialog-title"
                    open={this.state.confirmDiscardChangesModalOpen}
                    fullWidth={true}
                    maxWidth={'md'}
                    fullScreen={fullScreen}>
                    <DialogTitle>{t('general.unsavedChangesDetected')}</DialogTitle>
                    <DialogContent dividers>
                        <br />
                        <span>{t('general.unsavedChangesOnPageConfirmLeave')}</span>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            name="dialog-btn-locationedit-discardConfirm"
                            variant="contained"
                            color="primary"
                            onClick={this.confirmDiscard}>
                            {t('buttons.discard')}
                        </Button>
                        <Button
                            name="dialog-btn-locationedit-discardCancel"
                            variant="contained"
                            onClick={this.cancelDiscard}>
                            {t('buttons.cancel')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <BisLookupContainer
                    open={this.state.bisLookupOpen}
                    searchTerm={this.state.bisLookupSearchTerm}
                    searchBusinessId={this.state.bisLookupBusinessIdTerm}
                    cancel={this.bisLookupResultCancel}
                    save={this.bisLookupResultSave}
                />
                <DuplicateItemsDialog
                    open={this.state.duplicateViewOpen}
                    cancel={this.handleDuplicateViewClick}
                    items={this.props.duplicates[this.state.editActionId]}
                    type={TypeLocation}
                    history={this.props.history}
                />
                <LocationOrderValueSetDialog
                    open={this.state.orderValueSetDialogOpen}
                    orderValueWithoutDiscount={this.getLocationValueWithoutDiscount()}
                    orderValue={this.state.location.orderValueWithoutVat}
                    cancel={this.setNewOrderValueClick}
                    save={this.setNewOrderValueSave}
                />
            </Grid>
        );
    };

    getHeader = () => {
        if (this.state.location == null) return null;

        return (
            <h2>
                <Button name="backButton" onClick={this.onCancel}>
                    <i className="fas fa-chevron-left fa-2x"></i>&nbsp;&nbsp;
                </Button>
                {this.state.location.name} - <em>{t('general.editing')}</em>
            </h2>
        );
    };

    render() {
        let saving = this.state.saving;

        if (saving)
            return (
                <div>
                    <this.getHeader />
                    <fieldset disabled>
                        <this.getform />
                    </fieldset>
                </div>
            );
        return (
            <div>
                <this.getHeader />
                <this.getform />
                <br />
                <br />
            </div>
        );
    }
}

export default withStyles(styles)(LocationEditView);
