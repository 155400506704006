import React, { useCallback, useState, useEffect, useRef } from 'react';
import { DialogActions, DialogContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import EmailInput from '../common/emailinput';
import ParameterSelector from '../common/parameterselector';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import DeliveryDatePicker from './deliveryDatePicker';
import OrderTypeSelector from './ordertypeselector';
import Order from '../domain/order';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));
export default function OrderMassEditDialog(props) {
    const [formData, setFormData] = useState({ orderType: 'Normal' });
    const [earliestDeliveryDate, setEarliestDeliveryDate] = useState(null);
    const [earliestDeliveryDateDetails, setEarliestDeliveryDateDetails] = useState(null);
    const customerView = props.customerView;
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const prevFormDataRef = useRef();
    const prevFormData = prevFormDataRef.current;
    const getEarliestDeliveryTimeProps = props.getEarliestDeliveryTime;
    const { t } = useTranslation();

    const getEarliestDeliveryTime = useCallback(async () => {
        if (props.open) {
            const earliestDeliveryDateInfo = await getEarliestDeliveryTimeProps(formData['orderType']);
            setEarliestDeliveryDate(earliestDeliveryDateInfo.earliestDate);
            setEarliestDeliveryDateDetails(earliestDeliveryDateInfo.earliestDateDetails);
        }
    }, [formData, getEarliestDeliveryTimeProps, props.open]);

    useEffect(() => {
        prevFormDataRef.current = formData;
    });

    useEffect(() => {
        if (prevFormData && prevFormData['orderType'] !== formData['orderType']) {
            const data = cloneDeep(formData);
            data['deliveryTime'] = null;
            data['earlyDeliveryTime'] = null;
            setFormData(data);
            getEarliestDeliveryTime();
        } else if (props.open && !earliestDeliveryDate) {
            getEarliestDeliveryTime();
        }
    }, [formData, getEarliestDeliveryTime, prevFormData, earliestDeliveryDate, props.open]);

    const onSave = async () => {
        const dataToSend = {
            ...formData,
        };
        await props.save(dataToSend);
    };

    const onCancel = () => {
        setFormData({});
        props.cancel();
    };

    const onChange = (e) => {
        const data = cloneDeep(formData);
        data[e.target.name] = e.target.value;
        if (e.target.name === 'deliveryMethod') {
            data['deliveryTime'] = null;
            data['earlyDeliveryTime'] = null;
        }
        setFormData(data);
    };

    const onEmailChange = (emails) => {
        let data = formData;
        if (emails.length === 0) {
            delete data['confirmationEmail'];
        } else {
            data['confirmationEmail'] = emails;
        }
        setFormData(data);
    };

    const onDateChange = async (date) => {
        let newDate = null;
        let earlyDate = formData['earlyDeliveryTime'];
        const selectedDate = moment(date);

        if (selectedDate.isValid()) {
            selectedDate.hour(12).minute(0).second(0);
            newDate = selectedDate;
        } else {
            newDate = null;
        }

        if (props.customerView) {
            earlyDate = newDate;
        } else if (newDate === null) {
            earlyDate = null;
        } else if (moment(earlyDate).isAfter(moment(newDate), 'day')) {
            earlyDate = newDate;
        }

        const data = cloneDeep(formData);

        let deliveryTime = newDate ? newDate : null;
        if (deliveryTime && deliveryTime instanceof dayjs) {
            deliveryTime = deliveryTime.format();
        }
        let earlyDeliveryTime = earlyDate ? earlyDate : null;
        if (earlyDeliveryTime && earlyDate instanceof dayjs) {
            earlyDeliveryTime = earlyDeliveryTime.format();
        }
        data['deliveryTime'] = deliveryTime;
        data['earlyDeliveryTime'] = earlyDeliveryTime;
        setFormData(data);
    };

    const onEarlyDateChange = (date) => {
        const selectedDate = moment(date);
        let newDate = null;

        if (selectedDate.isValid()) {
            newDate = selectedDate.hour(12).minute(0).second(0);
            if (moment(newDate).isAfter(moment(formData['deliveryTime'], 'day'))) {
                toast.error(t('order.earliestDeliveryDateCannotByLaterThanDeliveryDate'), { autoClose: 5000 });
                newDate = null;
            }
        }

        const data = cloneDeep(formData);
        data['earlyDeliveryTime'] = newDate;
        setFormData(data);
    };

    const isSaveDisabled = () => {
        if (formData['orderType'] === Order.OrderTypeUrgent()) return false;
        return formData['deliveryTime'] != null && formData['earlyDeliveryTime'] == null;
    };

    return (
        <Dialog
            aria-labelledby="mass-edit-dialog"
            open={props.open}
            fullWidth={true}
            maxWidth={'md'}
            fullScreen={fullScreen}>
            <DialogContent dividers>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {t('order.changesWillBeCopiedToSelectedOrders')}
                    </Grid>
                    {!customerView && (
                        <Grid item xs={12} sm={6}>
                            <OrderTypeSelector
                                handleChange={(value) => onChange({ target: { value, name: 'orderType' } })}
                                selected={formData['orderType']}
                            />
                        </Grid>
                    )}
                    {earliestDeliveryDateDetails && (
                        <Grid item xs={12} md={6}>
                            <h3>Toimitusajat</h3>
                            {earliestDeliveryDateDetails.map((del) => (
                                <div key={del.productType.id}>
                                    {del.earliestDeliveryDate && (
                                        <React.Fragment>
                                            {dayjs(del.earliestDeliveryDate).format('DD.MM.YYYY')}&nbsp;-&nbsp;
                                            {del.productType.name}
                                        </React.Fragment>
                                    )}
                                    {!del.earliestDeliveryDate && (
                                        <React.Fragment>
                                            <strong>Ei kapasiteettia</strong>&nbsp;
                                            {del.productType.name}
                                        </React.Fragment>
                                    )}
                                </div>
                            ))}
                        </Grid>
                    )}
                    {!customerView && (
                        <Grid item xs={12} md={6}>
                            <DeliveryDatePicker
                                label={t('general.deliveryDate') + ' *'}
                                value={formData['deliveryTime'] ? formData['deliveryTime'] : null}
                                onChange={onDateChange}
                                id={'mass-edit-delivery-time'}
                                name={'mass-edit-delivery-time'}
                                earliestDeliveryDate={earliestDeliveryDate}
                                //onOpen={onOpenDeliveryDatePicker}
                                disabled={!earliestDeliveryDate}
                                deliveryMethod={formData['deliveryMethod']}
                            />
                        </Grid>
                    )}
                    {!customerView && formData['orderType'] !== Order.OrderTypeUrgent() && (
                        <Grid item xs={12} md={6}>
                            <DeliveryDatePicker
                                label={t('order.earliestDeliveryDate') + ' *'}
                                value={formData['earlyDeliveryTime'] ? formData['earlyDeliveryTime'] : null}
                                onChange={onEarlyDateChange}
                                id={'mass-edit-early-delivery-time'}
                                name={'mass-edit-early-delivery-time'}
                                latestDeliveryDate={formData['deliveryTime']}
                                disabled={!moment(formData['deliveryTime']).isValid()}
                                enableAllWeekDays={true}
                                //onOpen={onOpenDeliveryDatePicker}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <ParameterSelector
                            values={props.parameters}
                            category={'DeliveryMethod'}
                            selected={formData.deliveryMethod ? formData.deliveryMethod : null}
                            handleChange={(type, value) =>
                                onChange({ target: { value: { id: value }, name: 'deliveryMethod' } })
                            }
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="deliveryTerms"
                            label={t('parameterCategories.DeliveryTerms')}
                            className={classes.root}
                            onChange={onChange}
                            margin="normal"
                            name="deliveryTerms"
                            defaultValue={props.defaultValues.deliveryTerms}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="ordererName"
                            label={t('order.ordererName')}
                            className={classes.root}
                            onChange={onChange}
                            margin="normal"
                            name="ordererName"
                            defaultValue={props.defaultValues.ordererName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="ordererTel"
                            label={t('order.deliveryRecipientTel')}
                            className={classes.root}
                            onChange={onChange}
                            margin="normal"
                            name="ordererTel"
                            defaultValue={props.defaultValues.ordererTel}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <EmailInput
                            required={true}
                            name="confirmationEmail"
                            onChange={onEmailChange}
                            emails={
                                formData['confirmationEmail']
                                    ? formData['confirmationEmail']
                                    : props.defaultValues.confirmationEmail
                            }
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            id="reference"
                            label={t('general.yourReference')}
                            className={classes.root}
                            onChange={onChange}
                            margin="normal"
                            name="reference"
                            defaultValue={props.defaultValues.reference}
                        />
                    </Grid>
                    {!customerView && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                multiline={true}
                                id="packingInstructions"
                                name="packingInstructions"
                                label={t('general.packingInstructions')}
                                className={classes.root}
                                margin="normal"
                                onChange={onChange}
                            />
                        </Grid>
                    )}
                    {!customerView && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                multiline={true}
                                id="generalInfo"
                                name="generalInfo"
                                label={t('order.generalInfoToProduction')}
                                className={classes.root}
                                margin="normal"
                                onChange={onChange}
                            />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="default" name="mass-edit-cancel" onClick={onCancel}>
                    {t('buttons.cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    name="mass-edit-save"
                    onClick={onSave}
                    disabled={isSaveDisabled()}>
                    {t('buttons.save')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

OrderMassEditDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    customerView: PropTypes.bool,
    parameters: PropTypes.object.isRequired,
    getEarliestDeliveryTime: PropTypes.func.isRequired,
};
