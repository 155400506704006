import Ajax from './ajax';
import { LOGGED_OFF } from './authentication';

export const SETTINGS_FETCHING = 'settings/fetching';
export const SETTINGS_RETRIEVED = 'settings/retrieved';
export const APIKEYS_AVAILABLE = 'settings/apikeysavailable';
export const POSTAL_CODE_API_KEY = 'postalcodeapikey';

const initialState = {
    features: null,
    apiKeys: null,
    fetchingSettings: false,
    invoiceParameters: null,
    reportSettings: {},
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SETTINGS_FETCHING:
            sessionStorage.setItem(POSTAL_CODE_API_KEY, null);
            return {
                ...state,
                fetchingSettings: true,
            };
        case SETTINGS_RETRIEVED:
            sessionStorage.setItem(POSTAL_CODE_API_KEY, action.data.apiKeys.postalCodeApiKey);
            return {
                ...state,
                features: {
                    capacityPlanningInUse: action.data.features.capacityPlanningInUse,
                    salesInvoiceIntegration: action.data.features.salesInvoiceIntegration,
                    surveysInUse: action.data.features.surveysInUse,
                    energyCalculatorInUse: action.data.features.energyCalculatorInUse,
                    capacityPlanningMode: action.data.features.capacityPlanningMode,
                },
                apiKeys: { postalCodeApiKey: action.data.apiKeys.postalCodeApiKey },
                invoiceParameters: action.data.invoiceParameters,
                fetchingSettings: false,
                reportSettings: {
                    lostSalesReportLink: action.data.reportSettings.lostSalesReportLink,
                },
            };
        case LOGGED_OFF:
            sessionStorage.setItem(POSTAL_CODE_API_KEY, null);
            return initialState;
        default:
            return state;
    }
};

export const getSettings = () => {
    return async (dispatch) => {
        dispatch({
            type: SETTINGS_FETCHING,
        });
        try {
            const resp = await Ajax.get('api/settings');
            dispatch({ type: SETTINGS_RETRIEVED, data: resp.data });
            dispatch({ type: APIKEYS_AVAILABLE });
        } catch (err) {}
    };
};
